import React, { useEffect, useState, useRef } from "react";
import { FaTimes, FaDownload } from "react-icons/fa";
import {
  useAdminRejectEmployeeRequestDocument,
  useAdminDocumentApproval,
} from "../../queries/systemPolicyQueries";
import { toast } from "react-toastify";
import { DOCUMENT_APPROVAL_STAUS } from "../../Consts/consts";
import useFetchFile from "../../hooks/useFetchFile";
import { SYSTEM_POLICY_FILES_URL } from "../../Consts/filePathConst";
const DocumentApprove = ({ isOpen, onClose, hrComment, setHrComment, selectedDocument }) => {
  const src = selectedDocument.src;
  useEffect(() => {
    if (src) {
      const fetchSystemPolicyFile = async () => {
        try {
          const getFileSrc = await getFile(`${SYSTEM_POLICY_FILES_URL}/${src}`);
          setFileSrc(getFileSrc);
        } catch (error) {
          console.error("Error fetching file:", error);
        }
      };
      fetchSystemPolicyFile();
    }
  }, [src]);
  const approveMutation = useAdminDocumentApproval();
  const rejectMutation = useAdminRejectEmployeeRequestDocument();
  const getFile = useFetchFile();
  const [fileSrc, setFileSrc] = useState(null);
  const downloadLinkRef = useRef(null);
  if (!isOpen || !selectedDocument) return null;

  const handleFormSubmit = async (status) => {
    if (!selectedDocument) return;

    const payload = new FormData();
    payload.append("document_id", selectedDocument.document_id);
    payload.append("employee_id", selectedDocument.employee_id);
    payload.append("comments", hrComment);

    const mutation = status === "approve" ? approveMutation : rejectMutation;

    mutation.mutate(payload, {
      onSuccess: () => {
        toast.success(`Document ${status}d successfully`);
        onClose();
      },
      onError: (error) => {
        toast.error(`Error ${status}ing document`);
        console.error(error);
      },
    });
  };

  
  const approvalStatusId = selectedDocument.approval_status_id;
  const isApproved = Number(approvalStatusId) === DOCUMENT_APPROVAL_STAUS.approved;
  const isDeclined = Number(approvalStatusId) === DOCUMENT_APPROVAL_STAUS.declined;
  const isPending = Number(approvalStatusId) === DOCUMENT_APPROVAL_STAUS.pending;
  const isReadyForApproval = Number(approvalStatusId) === DOCUMENT_APPROVAL_STAUS.readyForApproval;
  const isInProcess = Number(approvalStatusId) === DOCUMENT_APPROVAL_STAUS.inProcess;
  const hasFile = selectedDocument.file_name;
 const handleDownload = () => {
    if (!fileSrc) {
      toast.error("No file source available.");
      return;
    }
    if (downloadLinkRef.current) {
      downloadLinkRef.current.click();
      toast.success("Document downloaded successfully!");
    } else {
      toast.error("Download failed.");
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96 relative">
        {/* Close Button */}
        <button className="absolute top-3 right-3 text-gray-600 hover:text-gray-900" onClick={onClose}>
          <FaTimes size={18} />
        </button>

        <h2 className="text-lg font-semibold mb-4">Review Document</h2>

        {/* Employee Name */}
        <div className="mb-3">
          <label className="block font-medium">Employee Name</label>
          <p className="p-2 border rounded-md bg-gray-100">{selectedDocument.employee_name}</p>
        </div>

        {/* Document Name */}
        <div className="mb-3">
          <label className="block font-medium">Document Name</label>
          <p className="p-2 border rounded-md bg-gray-100">{selectedDocument.document_name}</p>
        </div>

        {/* HR Note */}
        {isReadyForApproval && hasFile && (
          <div className="mb-3">
            <label className="block font-medium">HR Note</label>
            <p className="p-2 border rounded-md bg-gray-100">{selectedDocument.hr_comments || "No Note"}</p>
          </div>
        )}
        {isInProcess && !hasFile && (
          <div className="mb-3">
            <label className="block font-medium">HR Note</label>
            <p className="p-2 border rounded-md bg-gray-100">Document needs to be uploaded</p>
          </div>
        )}

        {/* Document Attachment */}
        {hasFile && (
          <div className="mb-3 flex items-center justify-between">
            <div>
              <label className="block font-medium">Document Attachment</label>
              <div className="text-left flex items-center gap-3">
                {selectedDocument.file_name}
                <FaDownload className="cursor-pointer" onClick={handleDownload} />
              </div>
            </div>
          </div>
        )}

        {/* Comments */}
        {!isApproved && !isDeclined && hasFile && (
          <div className="mb-3">
            <label className="block font-medium">Comments</label>
            <textarea
              className="w-full p-2 border rounded-md"
              rows="3"
              value={hrComment}
              onChange={(e) => setHrComment(e.target.value)}
              placeholder="Enter your comments..."
            />
          </div>
        )}

        {/* Action Buttons */}
        {!isApproved && !isDeclined && hasFile && (
          <div className="flex justify-end gap-2">
            <button
              onClick={() => handleFormSubmit("reject")}
              className="px-4 py-2 bg-red-600 text-white rounded-md"
              disabled={rejectMutation.isLoading}
            >
              {rejectMutation.isLoading ? "Rejecting..." : "Reject"}
            </button>
            <button
              onClick={() => handleFormSubmit("approve")}
              className="px-4 py-2 bg-green-600 text-white rounded-md"
              disabled={approveMutation.isLoading}
            >
              {approveMutation.isLoading ? "Approving..." : "Approve"}
            </button>
          </div>
        )}
  <a
          ref={downloadLinkRef}
          href={fileSrc || "#"}
          download={selectedDocument.file_name || "downloaded_document.pdf"}
          style={{ display: "none" }}
        >
          Download
        </a>
        {(isApproved || isDeclined || isPending) && (
          <div className="flex justify-end">
            <button onClick={onClose} className="px-4 py-2 bg-gray-600 text-white rounded-md">
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentApprove;
