import React, { useState } from "react";
import moment from "moment";
import { DOCUMENT_APPROVAL_STAUS } from "../../Consts/consts";
import { useSelector } from "react-redux";
import userRoleAuth from "../../utils/userRoleAuth/userRoleAuth";
import userRoles from "../../config/userRoles";
import { editIcon, viewIcon } from "../../Icons/Icons";
import UploadDocument from "../../containers/Documents/UploadDocument";
import DocumentApprove from "../../containers/Documents/DocumentApprove";
import DocumentDetailsModal from "./DocumentDetailsModal";

const DocumentListData = ({ employeeRequestList }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [hrComment, setHrComment] = useState("");
  const [modalType, setModalType] = useState("view");
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  const loggedInUser = useSelector((state) => state.user.auth);
  const isHr = userRoleAuth(loggedInUser, userRoles.HR);
  const isAdmin = userRoleAuth(loggedInUser, userRoles.TENANTADMIN);
  const isAdminOrHR = isHr || isAdmin;

  const handleActionClick = (doc) => {
    setSelectedDocument(doc);
    setHrComment("");
    setModalType("edit");
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedDocument(null);
  };

  const handleCloseDetailsModal = () => {
    setIsDetailsModalOpen(false);
    setSelectedDocumentId(null);
  };

  const handleViewDetails = (doc) => {
    setSelectedDocumentId(doc.document_id);
    setIsDetailsModalOpen(true);
  };

  return (
    <>
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-500">
        <thead className="text-xs font-medium uppercase bg-gray-50 text-[#4B5563] border-b border-t tracking-wider">
          <tr>
            <th
              scope="col"
              className={`py-2 ${
                isAdminOrHR ? "w-[20%] px-10" : "w-[25%] px-12"
              }`}
            >
              Document Name
              <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
            </th>

            <th
              scope="col"
              className={`py-2 ${
                isAdminOrHR ? "w-[15%] px-8" : "w-[20%] px-10"
              }`}
            >
              {isAdminOrHR ? "Requested By" : "Document Subject"}
              <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
            </th>

            <th scope="col" className="py-2 w-[15%] px-8">
              Requested Date
              <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
            </th>

            <th scope="col" className="py-2 w-[15%] px-8">
              Approval Status
              <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
            </th>

            {isAdminOrHR && (
              <th scope="col" className="py-2 w-[15%] px-8">
                Action
                <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
              </th>
            )}

            <th scope="col" className="py-2 w-[5%] px-4"></th>
          </tr>
        </thead>
        <tbody>
          {employeeRequestList?.data?.length > 0 ? (
            employeeRequestList.data.map((doc, index) => {
              const approvalStatusId = Number(doc.approval_status_id);
              const approvedStatusLabel =
                approvalStatusId === DOCUMENT_APPROVAL_STAUS.approved
                  ? "Approved"
                  : approvalStatusId === DOCUMENT_APPROVAL_STAUS.declined
                  ? "Declined"
                  : approvalStatusId === DOCUMENT_APPROVAL_STAUS.inProcess
                  ? "In-Process"
                  : approvalStatusId ===
                    DOCUMENT_APPROVAL_STAUS.readyForApproval
                  ? "Ready for approval"
                  : "Pending";

              return (
                <tr key={doc.id || index} className="border-b">
                  <td
                    className="text-left py-3 px-5 cursor-pointer"
                    onClick={() => handleViewDetails(doc)}
                  >
                    {doc.document_name}
                  </td>

                  <td className="text-left py-3 px-5">
                    {isAdminOrHR ? doc.employee_name : doc.subject || "N/A"}
                  </td>

                  <td className="text-center py-3 px-5">
                    {moment(new Date(doc.created_date)).format("DD MMM YYYY")}
                  </td>

                  <td className="text-center py-3 px-5">
                    <span
                      className={`status ${
                        approvedStatusLabel === "Approved"
                          ? "status--green"
                          : approvedStatusLabel === "Declined"
                          ? "status--red"
                          : ""
                      }`}
                    >
                      {approvedStatusLabel}
                    </span>
                  </td>
                  {isAdminOrHR && (
                    <td className="text-center py-3 px-5">
                      {(isHr &&
                        approvalStatusId === DOCUMENT_APPROVAL_STAUS.pending) ||
                      (isAdmin &&
                        approvalStatusId ===
                          DOCUMENT_APPROVAL_STAUS.readyForApproval) ? (
                        <button
                          onClick={() => handleActionClick(doc)}
                          className="py-2 px-3"
                        >
                          {editIcon}
                        </button>
                      ) : (
                        <button
                          onClick={() => handleViewDetails(doc)}
                          className="py-2 px-3"
                        >
                          {viewIcon}
                        </button>
                      )}
                    </td>
                  )}
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                colSpan={isAdminOrHR ? "6" : "5"}
                className="text-center py-5"
              >
                No documents found
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {selectedDocument && isHr && (
        <UploadDocument
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          modalType={modalType}
          hrComment={hrComment}
          setHrComment={setHrComment}
          selectedDocument={selectedDocument}
          handleSubmit={() => setIsModalOpen(false)}
        />
      )}

      {selectedDocument && isAdmin && (
        <DocumentApprove
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          modalType={modalType}
          hrComment={hrComment}
          setHrComment={setHrComment}
          selectedDocument={selectedDocument}
          handleSubmit={() => setIsModalOpen(false)}
        />
      )}

      {selectedDocumentId && isDetailsModalOpen && (
        <DocumentDetailsModal
          documentId={selectedDocumentId}
          onClose={handleCloseDetailsModal}
        />
      )}
    </>
  );
};

export default DocumentListData;
