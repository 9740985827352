import { Types } from "../constants/actionTypes";
import { getSettingValue, settingCategory } from "../../utils/Setting/setting";

const isNull = ["Nill", null, undefined, "NA", "nill", "undefined"];


const initialState = {
  auth: {
    user: "",
    accessToken: "",
    roles: [],
    connectedAthority: isNull.includes(localStorage?.getItem("connected_authority")) ? {} : JSON.parse(localStorage?.getItem("connected_authority")),
    recentNotice: localStorage?.getItem("recent_notice") || "",
    profileImage: "",
    persist: JSON.parse(localStorage?.getItem("persist")) || false,
  },
  formSubmitted: false,
  userProfile: {},
  browserHistory: [],
  isBrowseHistory: true,
  hrDetails: [],
  managerDetails: [],
  tenantSetting: [],
  settingValue: {
    isHrLeaveApprovalRequired: false,
    isHrLeaveApprovalRequiredForAdditionalLeaves:false
   
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.LOGIN:
      localStorage.setItem("persist", action.payload.user.persist);
      return {
        ...state,
        auth: action.payload.user,
        formSubmitted: false, // after update user formsubmition reset
        browserHistory: [],
        isBrowseHistory: false,
      };
    case Types.ADD_USER:
      return {
        ...state,
        auth: action.payload.user,
        formSubmitted: false, // after update user formsubmition reset
      };
    case Types.UPDATE_USER:
      localStorage.setItem(
        "connected_authority",
        JSON.stringify(action.payload.user.connectedAthority)
      );
      localStorage.setItem("recent_notice", action.payload.user.recentNotice);
      return {
        ...state,
        auth: action.payload.user,
        formSubmitted: false, // after update user formsubmition reset
      };
    case Types.UPDATE_PROFILE_PICTURE:
      return {
        ...state,
        auth: {
          ...state.profile,
          profileImage: action.payload.image,
        },
      };
    case Types.FORM_SUBMITION_STATUS:
      return {
        ...state,
        formSubmitted: action.payload.status,
      };
    case Types.USER_PROFILE:
      const {
        photo,
        first_name,
        middle_name,
        last_name,
        line_manager_name,
        location_name,
      } = action.payload.profile;
      return {
        ...state,
        userProfile: {
          ...action.payload.profile,
          photo: `${photo}`,
          first_name: isNull.includes(first_name) ? "" : first_name,
          middle_name: isNull.includes(middle_name) ? "" : middle_name,
          last_name: isNull.includes(last_name) ? "" : last_name,
          line_manager_name: isNull.includes(line_manager_name)
            ? "Not updated"
            : line_manager_name,
          location_name: isNull.includes(location_name)
            ? "Not updated"
            : location_name,
        },
        hrDetails: action.payload.hrDetails,
        managerDetails: action.payload.managerDetails,
      }; //returns data from fetch profile api
    case Types.UPDATE_USER_PROFILE:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          email: action?.payload?.email || "",
          first_name: action?.payload?.first_name || "",
          last_name: action?.payload?.last_name || "",
          middle_name: action?.payload?.middle_name || "",
          phone: action?.payload?.phone || "",
          photo: action?.payload?.photo || "",
          personal_email: action.payload.personal_email || ""
        },
      }; //updates the user profile data
    case Types.LOGOUT:
      return {
        ...initialState,
        isBrowseHistory: false,
        browserHistory: [],
      }; // will clear the user data and set to initial state
    case Types.BROWSER_HISTORY:
      if (state.isBrowseHistory) {
        return {
          ...state,
          browserHistory: [action.payload],
          isBrowseHistory: false,
        };
      } else {
        return {
          ...state,
          browserHistory: [],
        };
      } // keeps the browser history that can be used to to reidrect after auth to the requested url if no auth found
    case Types.TENANT_SETTING:
      return {
        ...state,
        tenantSetting: action.payload,
        settingValue: {
          ...state.settingValue,
          isHrLeaveApprovalRequired: getSettingValue(
            settingCategory.isHrLeaveApprovalRequired,
            action.payload
          ),
          isHrLeaveApprovalRequiredForAdditionalLeaves: getSettingValue(
            settingCategory.isHrLeaveApprovalRequiredForAdditionalLeaves, 
            action.payload
          ),
          isAdminApprovalRequiedForEmployeeDocument: getSettingValue(
            settingCategory.isAdminApprovalRequiedForEmployeeDocument,
            action.payload
          )
        },
      };
    default:
      return state;
  }
};

export default reducer;
