export const LEAVE_MODAL_ACTIONS = {
  approve: "approve",
  decline: "decline",
  revoke: "revoke",
};

export const LEAVE_APPROVAL_STATUS_ID = {
  approved: 1,
  pending: 2,
  decline: 3,
};

export const LEAVE_APPROVAL_STATUS_LABEL = {
  approved: "Approved",
  pending: "Pending",
  decline: "Declined",
  canceled: "Canceled",
};
export const addNewShiftConst = {
  shiftType: {
    clockBased: 1,
    durationBased: 2,
  },
};

export const shiftTypeConst = {
  clockBased: 1,
  durationBased: 2,
};

export const weekDays = [
  { id: 1, day: "Monday" },
  { id: 2, day: "Tuesday" },
  { id: 3, day: "Wednesday" },
  { id: 4, day: "Thursday" },
  { id: 5, day: "Friday" },
  { id: 6, day: "Saturday" },
  { id: 7, day: "Sunday" },
];

export const shiftTagTypes = {
  employee: "employee",
  employeeType: "employee type",
  location: "location",
  department: "department",
  designation: "designation",
};

export const SYSTEM_POLICY_APPROVAL_STATUS = {
  pending: 2,
  approved: 1,
  declined: 3,
};

export const DOCUMENT_APPROVAL_STAUS = {
  pending: 2,
  approved: 1,
  declined: 3,
  inProcess: 4,
  readyForApproval:5
}
