import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useRejectEmployeeRequestDocument, useAcceptEmployeeRequestDocument, useUploadEmployeeDocument } from "../../queries/systemPolicyQueries";
import { useSelector } from "react-redux";
import userRoleAuth from "../../utils/userRoleAuth/userRoleAuth";
import userRoles from "../../config/userRoles";
import { toast } from "react-toastify";
import { closeIconRoundRed } from "../../Icons/Icons";
import { DOCUMENT_APPROVAL_STAUS } from "../../Consts/consts";
import { useForm, Controller } from "react-hook-form";

const UploadDocument = ({
  isOpen,
  onClose,
  hrComment,
  setHrComment,
  selectedDocument,
}) => {
  const acceptMutation = useAcceptEmployeeRequestDocument();
  const rejectMutation = useRejectEmployeeRequestDocument();
  const uploadMutation = useUploadEmployeeDocument();
  const [selectedFile, setSelectedFile] = useState(null);
  const approvalStatusId = selectedDocument.approval_status_id;
  const isDeclined = Number(approvalStatusId) === DOCUMENT_APPROVAL_STAUS.declined;
  const isPending = Number(approvalStatusId) === DOCUMENT_APPROVAL_STAUS.pending;
  const isApproved = Number(approvalStatusId) === DOCUMENT_APPROVAL_STAUS.approved;
  const isReadyForApproval = Number(approvalStatusId) === DOCUMENT_APPROVAL_STAUS.readyForApproval;
  const [isInProcess, setIsInProcess] = useState(Number(approvalStatusId) === DOCUMENT_APPROVAL_STAUS.inProcess);

  const loggedInUser = useSelector((state) => state.user.auth);
  const isHr = userRoleAuth(loggedInUser, userRoles.HR);

  // Use react-hook-form
  const { control, handleSubmit, setValue, formState: { errors } } = useForm();

  const handleFormSubmit = async (data, status) => {
    if (!selectedDocument) return;

    const payload = new FormData();

    if (status === "submit" && selectedFile) {
      payload.append("document_id", selectedDocument.document_id);
      payload.append("employee_id", selectedDocument.employee_id);
      payload.append("document_type_id", selectedDocument.document_type_id);
      payload.append("comments", data.hrComment); 
      payload.append("attachments", selectedFile);

      uploadMutation.mutate(payload, {
        onSuccess: () => {
          toast.success("Document and attachment uploaded successfully");
          onClose();
        },
        onError: (error) => {
          toast.error("Error uploading document");
          console.error(error);
        },
      });
    } else if (status === "approve" || status === "reject") {
      payload.append("document_id", selectedDocument.document_id);
      payload.append("employee_id", selectedDocument.employee_id);
      payload.append("comments", data.hrComment); 

      const mutation = status === "approve" ? acceptMutation : rejectMutation;

      mutation.mutate(payload, {
        onSuccess: () => {
      
          if (status === "approve") {
            toast.success("Document has been approved");
            setIsInProcess(true); 
          } else {
            toast.success("Document has been rejected");
            onClose(); 
          }
        },
        onError: (error) => {
          toast.error(`Error ${status}ing document`);
          console.error(error);
        },
      });
    } else {
      toast.info("Please attach a document before submitting.");
    }
  };

  const removeAttachment = () => setSelectedFile(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  if (!isOpen || !selectedDocument) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg w-96 shadow-lg">
          No document found.
        </div>
      </div>
    );
  }

  return (
<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
<div className="bg-white p-6 rounded-lg shadow-lg w-96 relative overflow-auto">
        <button className="absolute top-3 right-3 text-gray-600" onClick={onClose}>
          <FaTimes size={18} />
        </button>

        <h2 className="text-lg font-semibold mb-4">Review Document</h2>

        <form onSubmit={handleSubmit((data) => handleFormSubmit(data, "submit"))}>
          <div className="mb-3">
            <label className="block font-medium">Employee Name</label>
            <p className="p-2 border rounded-md bg-gray-100">{selectedDocument.employee_name}</p>
          </div>

          <div className="mb-3">
            <label className="block font-medium">Document Name</label>
            <p className="p-2 border rounded-md bg-gray-100">{selectedDocument.document_name}</p>
          </div>

          <div className="mb-3">
            <label className="block font-medium">HR Note</label>
            <Controller
              name="hrComment"
              control={control}
              defaultValue={hrComment}
              render={({ field }) => (
                <textarea
                  {...field}
                  className="w-full p-2 border rounded-md"
                  rows="3"
                  placeholder="Enter your comments..."
                />
              )}
            />
          </div>

          {isInProcess && (
            <div>
              <label className="block font-medium">Attach Document</label>
              <input
                type="file"
                name="attachments"
                onChange={handleFileChange}
                className="mb-3"
              />
              {selectedFile && (
                <div className="flex items-center mb-3">
                  <span>{selectedFile.name}</span>
                  <button onClick={removeAttachment} className="ml-2 text-red-500">
                    {closeIconRoundRed}
                  </button>
                </div>
              )}
            </div>
          )}

          <div className="flex justify-end gap-2">
            {isPending && !isInProcess && (
              <>
                <button
                 onClick={handleSubmit((data) => handleFormSubmit(data, "reject"))}

                  className="px-4 py-2 bg-red-600 text-white rounded-md"
                  disabled={rejectMutation.isLoading}
                >
                  {rejectMutation.isLoading ? "Rejecting..." : "Reject"}
                </button>
                <button
                  onClick={() => handleFormSubmit({ hrComment: hrComment }, "approve")}
                  className="px-4 py-2 bg-green-600 text-white rounded-md"
                  disabled={acceptMutation.isLoading}
                >
                  {acceptMutation.isLoading ? "Accepting..." : "Accept"}
                </button>
              </>
            )}

            {isDeclined || isApproved || isReadyForApproval ? (
              <button onClick={onClose} className="px-4 py-2 bg-gray-600 text-white rounded-md">
                Close
              </button>
            ) : null}

            {isInProcess && (
              <>
                <button onClick={onClose} className="px-4 py-2 bg-gray-600 text-white rounded-md">
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-md"
                  disabled={uploadMutation.isLoading}
                >
                  {uploadMutation.isLoading ? "Submitting..." : "Submit"}
                </button>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default UploadDocument;
