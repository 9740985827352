import React, { useState, useEffect, createContext, useRef } from "react";
import PreferenceTab from "../../components/Assets/AssetsTab/PreferenceTab";
import Select, { components } from "react-select";
import Search from "../../components/Header/Search/Search";
import LeaveApplicationList from "./LeaveApplicationList";
import ApproveLeaveModal from "./LeaveApplicationActionModal";
import { useSelector } from "react-redux";
import userRoleAuth from "../../utils/userRoleAuth/userRoleAuth";
import Pagination from "../../components/Pagination/Pagination";
import EmptyState from "../../components/EmptyState/EmptyState";
import PageLoader from "../../components/PageLoader/PageLoader";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import LeaveDetailsModal from "./LeaveDetailsModal";
import LeaveReportDownloadOption from "../../components/Leaves/ManageLeave/Overview/LeaveReportDownloadOption";
import { DatePicker, Descriptions } from "antd";
import dayjs from "dayjs";
import {
  useGetDepartmentList,
  useGetDesignationList,
  useGetLocationList,
} from "../../queries/employeeQueries";
import {
  useLeaveTypeList,
  useHrAdditionalLeaveApprovalList,
  useHrLeaveApprovalList,
  useManagerAdditionalLeaveApprovalList,
  useManagerLeaveApprovalList,
} from "../../queries/leaveQueries";
import userRoles from "../../config/userRoles";
import { LEAVE_MODAL_ACTIONS } from "../../Consts/consts";
import { infoIcon } from "../../Icons/Icons";
import { Modal } from "react-responsive-modal";
const { RangePicker } = DatePicker;

const LEAVE_REQUEST_TAB = 1;
const ADDITIONAL_LEAVE_REQUEST_TAB = 2;
const searchFilterInitialState = {
  page_no: 1,
  page_size: 10,
  status: "",
  leave_type: "",
  application_date: "",
  designation: "",
  department: "",
  location: "",
  from_date: "",
  to_date: "",
  filter: "",
  applied_for_date: "",
  sort_order: "",
  sort_by: "",
};

const FILTERS = {
  page_no: "page_no",
  page_size: "page_size",
  status: "status",
  leave_type: "leave_type",
  application_date: "application_date",
  designation: "designation",
  department: "department",
  location: "location",
  from_date: "from_date",
  to_date: "to_date",
  filter: "filter",
  applied_for_date: "applied_for_date",
  sort_order:"sort_order"
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};

export const LeaveApprovalContext = createContext({});
const tabs = ["All Status", "Approved", "Pending", "Declined", "Cancelled"];

const TaskLeaveApplications = () => {
  const navigate = useNavigate();
  const authState = useSelector((state) => state.user);
  const user = authState.auth;

  const { settingValue: setting } = authState;
  const searchRef = useRef();
  const { leaveApplicationId: leaveApplicationIdFromParams } = useParams();
  const [searchParams] = useSearchParams();
  const additionalLeaveApplicationidFromParam = searchParams.get(
    "additional-leave-details"
  );

  const isAdmin = userRoleAuth(user, userRoles.TENANTADMIN);
  const isHr = userRoleAuth(user, userRoles.HR);
  const isManager = userRoleAuth(user, userRoles.MANAGER);
  const isHrOrAdmin = isAdmin || isHr;

  const [selectedIds, setSelectedIds] = useState([]);
  const [infoMessageModalOpen, setInfoMessageModalOpen] = useState(false);

  const handleSetSelectedIds = (value, isSelected) => {
    if (value) {
      if (isSelected) {
        setSelectedIds((prev) => [...prev, value]);
      } else {
        setSelectedIds((prev) => [
          ...prev.filter(
            (i) =>
              i?.[
                isLeaveReqTab
                  ? "leave_application_id"
                  : isAdditionalLeaveReqTab
                  ? "additional_leave_application_id"
                  : ""
              ] !==
              value?.[
                isLeaveReqTab
                  ? "leave_application_id"
                  : isAdditionalLeaveReqTab
                  ? "additional_leave_application_id"
                  : ""
              ]
          ),
        ]);
      }
    } else {
      if (isSelected) {
        let data;
        if (isLeaveReqTab) {
          data = leaveApplications.map((i) => i.leave_application_id);
        } else if (isAdditionalLeaveReqTab) {
          data = leaveApplications.map(
            (i) => i.additional_leave_application_id
          );
        }
        setSelectedIds(leaveApplications);
      } else {
        setSelectedIds([]);
      }
    }
  };

  const [toggleState, toggleTab] = useState(
    additionalLeaveApplicationidFromParam
      ? ADDITIONAL_LEAVE_REQUEST_TAB
      : LEAVE_REQUEST_TAB
  );

  const handleToggleTab = (e) => {
    
    toggleTab(e);
    setSelectedIds([]);
    setSearchFilter(searchFilterInitialState); 
  
    if (searchRef.current) {
      searchRef.current.value = ""; 
    }
  };

  const [searchFilter, setSearchFilter] = useState(searchFilterInitialState);

  const {
    page_no,
    page_size,
    status,
    application_date,
    applied_for_date,
    department,
    designation,
    filter,
    from_date,
    leave_type,
    location,
    to_date,
    sort_order,
  } = searchFilter;

  const isLeaveReqTab = toggleState === LEAVE_REQUEST_TAB;
  const isAdditionalLeaveReqTab = toggleState === ADDITIONAL_LEAVE_REQUEST_TAB;

  const { data: getLocationList } = useGetLocationList();
  const { data: getDepartmentList } = useGetDepartmentList();
  const { data: getDesignationList } = useGetDesignationList();
  const { data: getLeaveTypeList } = useLeaveTypeList({});

  const {
    data: getHrLeaveApprovalList,
    isLoading: isGetHrLeaveApprovalListLoading,
    refetch: fetchHrLeaveApprovalList,
  } = useHrLeaveApprovalList(searchFilter, isLeaveReqTab, isHr);

  const {
    data: getHrAdditionalLeaveApprovalList,
    isLoading: isGetHrAdditionalLeaveApprovalListLoading,
    refetch: fetchHrAdditionalLeaveApprovalList,
  } = useHrAdditionalLeaveApprovalList(
    searchFilter,
    isAdditionalLeaveReqTab,
    isHr
  );

  const {
    data: getManagerLeaveApprovalList,
    isLoading: isGetManagerLeaveApprovalListLoading,
    refetch: fetchManagerLeaveApprovalList,
  } = useManagerLeaveApprovalList(searchFilter, isLeaveReqTab, isManager);

  const {
    data: getManagerAdditionalLeaveApprovalList,
    isLoading: isGetManagerAdditionalLeaveApprovalListLoading,
    refetch: fetchManagerAdditionalLeaveApprovalList,
  } = useManagerAdditionalLeaveApprovalList(
    searchFilter,
    isAdditionalLeaveReqTab,
    isManager
  );

  const handleRefetchData = () => {
    if (isLeaveReqTab) {
      if (isHr) {
        fetchHrLeaveApprovalList();
      } else if (isManager) {
        fetchManagerLeaveApprovalList();
      }
    } else if (isAdditionalLeaveReqTab) {
      if (isHr) {
        fetchHrAdditionalLeaveApprovalList();
      } else if (isManager) {
        fetchManagerAdditionalLeaveApprovalList();
      }
    }
  };

  let leaveApplications = [];
  let totalRecords = 0;

  const isLoading =
    isGetHrLeaveApprovalListLoading ||
    isGetHrAdditionalLeaveApprovalListLoading ||
    isGetManagerLeaveApprovalListLoading ||
    isGetManagerAdditionalLeaveApprovalListLoading;

  if (isHr) {
    if (isLeaveReqTab && Array.isArray(getHrLeaveApprovalList?.data)) {
      const { total, data } = getHrLeaveApprovalList;
      leaveApplications = data;
      totalRecords = total;
    } else if (
      isAdditionalLeaveReqTab &&
      Array.isArray(getHrAdditionalLeaveApprovalList?.approval_List)
    ) {
      const { approval_List, total } = getHrAdditionalLeaveApprovalList;
      leaveApplications = approval_List;
      totalRecords = total;
    } else {
      leaveApplications = [];
      totalRecords = 0;
    }
  } else if (isManager) {
    if (
      isLeaveReqTab &&
      Array.isArray(getManagerLeaveApprovalList?.approval_List)
    ) {
      const { total, approval_List } = getManagerLeaveApprovalList;
      leaveApplications = approval_List;
      totalRecords = total;
    } else if (
      isAdditionalLeaveReqTab &&
      Array.isArray(getManagerAdditionalLeaveApprovalList?.approval_List)
    ) {
      const { total, approval_List } = getManagerAdditionalLeaveApprovalList;
      leaveApplications = approval_List;
      totalRecords = total;
    } else {
      leaveApplications = [];
    }
  } else {
    leaveApplications = [];
  }

  const leaveTypesList = Array.isArray(getLeaveTypeList?.data)
    ? getLeaveTypeList?.data
    : [];

  const defaultLocationOption = { location_name: "Office: All", id: null };
  const defaultDesignationOption = {
    designation_title: "Designation: All",
    id: null,
  };
  const defaultDepartmentOption = {
    department_name: "Department: All",
    id: null,
  };
  const departmentList = Array.isArray(getDepartmentList?.result)
    ? [defaultDepartmentOption, ...getDepartmentList?.result]
    : [];

  const designationList = Array.isArray(getDesignationList?.result)
    ? [defaultDesignationOption, ...getDesignationList?.result]
    : [];

  const locationList = Array.isArray(getLocationList?.result)
    ? [defaultLocationOption, ...getLocationList?.result]
    : [];
    const sortOrder = [
     { label : "From (Newest first)" , value : "Desc"},
     {label : "From (Oldest first)" , value : "ASC"}
    
    ]

  const [details, setDetails] = useState(false);

  let isEligibleForAction; //approve, devcline action
  const isHrLeaveApprovalRequired = setting?.isHrLeaveApprovalRequired;
  const isHrAdditionalLeaveApprovalRequired =
    setting?.isHrLeaveApprovalRequiredForAdditionalLeaves;

  if (isLeaveReqTab) {
    isEligibleForAction = isHr ? isHrLeaveApprovalRequired : true;
  } else {
    isEligibleForAction = isHr ? isHrAdditionalLeaveApprovalRequired : true;
  }

  const handleDateRangeChange = (e) => {
    setSearchFilter((prev) => ({
      ...prev,
      from_date: e?.[0] ? dayjs(e?.[0]).format("YYYY-MM-DD") : null,
      to_date: e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null,
    }));
  };

  //FILTER SETUP ON BASE URL STARTS***

  const isFilterApplied =
    status ||
    application_date ||
    applied_for_date ||
    department ||
    designation ||
    filter ||
    from_date ||
    leave_type ||
    location ||
    to_date || sort_order;

  const handleSetSearchFilters = (e, source) => {
    if (source === FILTERS.page_no) {
      setSearchFilter((prev) => ({
        ...prev,
        [source]: e,
      }));
    } else {
      setSearchFilter((prev) => ({
        ...prev,
        [source]: e,
        page_no: 1,
      }));
    }
  };

  const clearFilters = () => {
    setSearchFilter(searchFilterInitialState);
    if (searchRef.current) {
      searchRef.current.value = "";
    }
  };

  const [isReportDownloadModal, setIsReportDownloadModal] = useState(false);
  const [leaveActionModal, setLeaveActionModal] = useState({
    isOpen: false,
    action: "",
  });

  const handleSetModal = (modal, status) => {
    setLeaveActionModal((prev) => ({
      ...prev,
      isOpen: status,
      action: modal || "",
    }));
  };

  const onSuccessLeaveAction = () => {
    setSelectedIds([]);
  };
  const handleSetDetailsModal = (e) => {
    setDetails(e);
    if (
      !e &&
      (leaveApplicationIdFromParams || additionalLeaveApplicationidFromParam)
    ) {
      navigate("/leave/manage-leave/employee-leave-applications", {
        replace: true,
      });
    }
  };
  useEffect(() => {
    if (leaveApplicationIdFromParams || additionalLeaveApplicationidFromParam) {
      handleSetDetailsModal(true);
    }
  }, []);

  const handleSetTabIndex = (e) => {
    handleSetSearchFilters(e === 0 ? null : e, FILTERS.status);
    setSelectedIds([]);
  };
  const leaveTypeOptions = [
    { label: "Type: All", value: "" },
    ...(leaveTypesList
      ? leaveTypesList.map((type) => ({
          value: type.id,
          label: type.leave_name,
        }))
      : []),
  ];
  return (
    <LeaveApprovalContext.Provider
      value={{
        isLeaveReqTab,
        isAdditionalLeaveReqTab,
        isEligibleForAction,
        handleSetSelectedIds,
        selectedIds,
      }}
    >
      {details ? (
        <LeaveDetailsModal
          openModal={details}
          onCloseModal={() => handleSetDetailsModal(false)}
          leaveApplicationId={
            isLeaveReqTab
              ? leaveApplicationIdFromParams
              : additionalLeaveApplicationidFromParam
          }
        />
      ) : null}

      {leaveActionModal.isOpen ? (
        <ApproveLeaveModal
          openModal={leaveActionModal.isOpen}
          onCloseModal={() => handleSetModal("", false)}
          leaveApplications={selectedIds}
          action={leaveActionModal.action}
          onSuccess={onSuccessLeaveAction}
        />
      ) : null}
      <div className="w-full overflow-x-scroll">
        <div className="px-10 py-6">
          <div className="my-2">
            <h2 className="text-lg font-semibold">Leave Approval</h2>
            <p>Manage all leave requests</p>

            <div className="flex items-center justify-between  border-b-2">
              <div className="flex items-center gap-12 pl-4 pt-6">
                <button
                  className={
                    toggleState === LEAVE_REQUEST_TAB
                      ? "tabs active-tabsnew text-blue-800 font-medium"
                      : "tabs"
                  }
                  onClick={() => handleToggleTab(LEAVE_REQUEST_TAB)}
                >
                  Leave
                </button>
                <button
                  className={
                    toggleState === ADDITIONAL_LEAVE_REQUEST_TAB
                      ? "tabs active-tabsnew text-blue-800 font-medium"
                      : "tabs"
                  }
                  onClick={() => handleToggleTab(ADDITIONAL_LEAVE_REQUEST_TAB)}
                >
                  Additional Leave
                </button>
              </div>
            </div>

            <PreferenceTab tabs={tabs} getTabIndex={handleSetTabIndex} />
            {!selectedIds.length ? (
              <div className="flex gap-3 justify-between">
                <div className="flex  flex-wrap gap-3 my-6">
                  <div className="flex flex-wrap gap-3">
                    {isAdditionalLeaveReqTab ? (
                      <DatePicker
                        value={
                          applied_for_date ? dayjs(applied_for_date) : null
                        }
                        placeholder=" Applied For"
                        onChange={(date) =>
                          handleSetSearchFilters(
                            date ? dayjs(date).format("YYYY-MM-DD") : null,
                            FILTERS.applied_for_date
                          )
                        }
                        className="h-10"
                      />
                    ) : (
                      <RangePicker
                        className="w-[300px] h-10"
                        placeholder={["From Date", "To Date"]}
                        onChange={handleDateRangeChange}
                        format="YYYY-MM-DD"
                        value={[
                          from_date ? dayjs(from_date) : null,
                          to_date ? dayjs(to_date) : null,
                        ]}
                      />
                    )}
                    <DatePicker
                      value={application_date ? dayjs(application_date) : null}
                      placeholder="Requested Date"
                      onChange={(date) =>
                        handleSetSearchFilters(
                          date ? dayjs(date).format("YYYY-MM-DD") : null,
                          FILTERS.application_date
                        )
                      }
                      className="h-10"
                    />
                  </div>
                  <Select
                    placeholder={"Designation: All"}
                    options={leaveTypeOptions}
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    value={{
                      value:
                        leaveTypesList.find(
                          (i) => Number(i.id) === Number(leave_type)
                        )?.id || 0,
                      label:
                        leaveTypesList.find(
                          (i) => Number(i.id) === Number(leave_type)
                        )?.leave_name || "Type: All",
                    }}
                    onChange={(e) =>
                      handleSetSearchFilters(e.value, FILTERS.leave_type)
                    }
                  />
                  <Select
                    placeholder={"Designation: All"}
                    options={designationList.map((i) => ({
                      label: i.designation_title,
                      value: i.id,
                    }))}
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    value={
                      designationList
                        .map((i) => ({
                          label: i?.designation_title,
                          value: i?.id,
                        }))
                        .find((opt) => opt.value === Number(designation)) || {
                        label: "Desgination: All",
                        value: 0,
                      }
                    }
                    onChange={(e) =>
                      handleSetSearchFilters(e.value, FILTERS.designation)
                    }
                  />
                  <Select
                    placeholder={"Department: All"}
                    options={departmentList.map((i) => ({
                      label: i.department_name,
                      value: i.id,
                    }))}
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    value={
                      departmentList
                        .map((i) => ({
                          label: i?.department_name,
                          value: i?.id,
                        }))
                        .find((opt) => opt.value === Number(department)) || {
                        label: "Department: All",
                        value: 0,
                      }
                    }
                    onChange={(e) =>
                      handleSetSearchFilters(e.value, FILTERS.department)
                    }
                  />
                  <Select
                    placeholder={"Location: All"}
                    options={locationList.map((i) => ({
                      label: i.location_name,
                      value: i.id,
                    }))}
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    value={
                      locationList
                        .map((i) => ({ label: i?.location_name, value: i?.id }))
                        .find((opt) => opt.value === Number(location)) || {
                        label: "Location: All",
                        value: 0,
                      }
                    }
                    onChange={(e) =>
                      handleSetSearchFilters(e.value, FILTERS.location)
                    }
                  />
                  <Select
                    placeholder={"Sort by: All"}
                    options={sortOrder}
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                   
                    onChange={(e) =>
                      handleSetSearchFilters(e.value, FILTERS.sort_order)
                    }
                  />

                  {isFilterApplied ? (
                    <button
                      className="text-blue-800 font-medium whitespace-nowrap flex items-center cursor-pointer h-10"
                      onClick={() => clearFilters()}
                    >
                      Clear Filters
                    </button>
                  ) : null}
                </div>
                <div className="flex flex-col flex-wrap gap-3 items-center my-6">
                  <div className="flex gap-1 w-full">
                    <button
                      type="button"
                      className="btn btn--border h-[38px]"
                      onClick={handleRefetchData}
                    >
                      <span className="material-symbols-outlined">refresh</span>
                    </button>
                    <Search /// refernce seracj ref
                      eleRef={searchRef}
                      onChange={(e) =>
                        handleSetSearchFilters(e, FILTERS.filter)
                      }
                    />{" "}
                    <span
                      className="cursor-pointer"
                      onClick={() => setInfoMessageModalOpen(true)}
                    >
                      {infoIcon}
                    </span>
                         <Modal
                    open={infoMessageModalOpen}
                    onClose={() => setInfoMessageModalOpen(false)}
                    center
                  >
                    <div className="p-4">
                      <p>
                        Use the search filter to find employees by name, leave
                        serial, employee serial, or email.
                      </p>
                    </div>
                  </Modal>
                  </div>
             
                  {isHrOrAdmin && toggleState === LEAVE_REQUEST_TAB ? (
                    <div className="relative w-full">
                      <button
                        onClick={() =>
                          setIsReportDownloadModal((prev) => !prev)
                        }
                        className="btn btn--border btn--full  text-[14px]"
                      >
                        <span>
                          <i className="ico-download mr-1"></i>
                        </span>
                        Exports
                      </button>
                      {isReportDownloadModal ? (
                        <div className="absolute top-12 right-0 bg-white z-50 shadow-sm border rounded-md p-2 w-auto">
                          <LeaveReportDownloadOption
                            setIsReportDownloadModal={setIsReportDownloadModal}
                            filters={searchFilter}
                          />
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <>
                <div className="flex items-center justify-between my-4">
                  <div className="flex items-center gap-3">
                    {/* <a href="" className="btn btn--border w-9 h-10">
                      <span>
                        <i className="ico-download"></i>
                      </span>
                    </a>
                    <a href="">
                      <img
                        src="/images/print-icon.svg"
                        alt=""
                        className="cursor-pointer p-2 btn btn--border w-9 h-10"
                      />
                    </a> */}

                    {/* <button
                      className="btn btn--border"
                      onClick={() => setRevoke(true)}
                      type="button"
                    >
                      Revoke
                      <span>
                        <img
                          src="/images/revoke-icon.svg"
                          alt=""
                          className="pl-3"
                        />
                      </span>
                    </button> */}

                    {isEligibleForAction && (
                      <>
                        <button
                          className="btn btn--red"
                          onClick={() =>
                            handleSetModal(LEAVE_MODAL_ACTIONS.decline, true)
                          }
                          type="button"
                        >
                          Decline
                          <span>
                            <img
                              src="/images/decline-white-icon.svg"
                              alt=""
                              className="pl-3"
                            />
                          </span>
                        </button>

                        <button
                          className="btn btn--green"
                          onClick={() =>
                            handleSetModal(LEAVE_MODAL_ACTIONS.approve, true)
                          }
                          type="button"
                        >
                          Approve
                          <span>
                            <img
                              src="/images/approve-white-icon.svg"
                              alt=""
                              className="pl-3"
                            />
                          </span>
                        </button>
                      </>
                    )}
                  </div>
                  <div className="flex items-center gap-2">
                    <p className="text-sm font-medium text-black">
                      {selectedIds.length} Leave request selected
                    </p>
                    <img
                      src="/images/decline-red-icon.svg"
                      alt=""
                      className="cursor-pointer p-1 border border-red-600 rounded-full"
                      onClick={() => setSelectedIds([])}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {isLoading ? (
          <PageLoader />
        ) : Array.isArray(leaveApplications) && leaveApplications.length > 0 ? (
          <LeaveApplicationList leaveApplications={leaveApplications} />
        ) : (
          <EmptyState />
        )}

        {Array.isArray(leaveApplications) && leaveApplications.length > 0 ? (
          <Pagination
            page={{
              pageNo: page_no,
              setPageNo: (e) => handleSetSearchFilters(e, FILTERS.page_no),
              pageSize: page_size,
              setPageSize: (e) => handleSetSearchFilters(e, FILTERS.page_size),
              totalRecords,
            }}
          />
        ) : null}
      </div>
    </LeaveApprovalContext.Provider>
  );
};

export default TaskLeaveApplications;
