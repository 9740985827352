import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import Select, { components } from "react-select";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { carryForwardTypeOptions } from "../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import ToggleSwitch from "../../../ToggleSwitch/ToggleSwitch";
import ReactSlidingPane from "react-sliding-pane";
import { closeIcon } from "../../../../Icons/Icons";
import { genderOptions } from "../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  useGetLeaveModeTypeList,
  useUpdateLeaveTypes,
  useCreateLeaveTypes,
} from "../../../../queries/leaveQueries";
import FormFeildInfo from "../../../FormFeildInfo/FormFeildInfo";
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};

const AddOrEditLeaveTypeModal = ({
  openModal,
  onCloseModal,

  leaveTypeData,
}) => {
  const isUpdateView = leaveTypeData?.id;
  const [isCarryForward, setCarryForward] = useState(
    leaveTypeData?.carry_forward || 0
  );
  const [isDocumentRequired, setIsDocumentRequired] = useState(
    Number(leaveTypeData?.is_document_required) || 0
  );

  const [selectedCarryForwardType, setSelectedCarryForwardType] = useState(
    carryForwardTypeOptions.find(
      (i) =>
        Number(i.value) ===
        Number(
          leaveTypeData?.carry_forward_type || carryForwardTypeOptions[0].value
        )
    )
  );
  const [isLop, setIsLop] = useState(Number(leaveTypeData?.is_lop || 0));
  const [isAutoIncrement, setIsAutoIncrement] = useState(
    Number(leaveTypeData?.is_auto_increment_enabled || 0)
  );
  const createLeaveTypeMutation = useCreateLeaveTypes();
  const updateLeaveTypeMutation = useUpdateLeaveTypes();
  const { data: leaveModulesOptionsData, isLoading: isLeaveModulesLoading } =
    useGetLeaveModeTypeList();

  const leaveModulesOptions = leaveModulesOptionsData?.data || [];
  const isEmptyGenderValues = [
    "",
    null,
    "NULL",
    "null",
    undefined,
    "undefined",
  ];
  // export const carryForwardTypeOptions = [
  //   { label: "Number of days", value: 1 },
  //   { label: "Percentage", value: 2 },
  // ];
  const numberOfDaysCarryForwrdOption = 1;
  const percentageTypeCarryForwrdOption = 2;
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      leaveTypeName: leaveTypeData?.leave_name || "",
      numberOfDaysAllowed: leaveTypeData?.number_days_allowed || "",
      carryForward: leaveTypeData?.carry_forward || 0,
      carryForwardLimit: leaveTypeData?.carry_forward_limit || 0,
      carryForwardType:
        leaveTypeData?.carry_forward_type || selectedCarryForwardType.value,
      carryForwardYears: leaveTypeData?.carry_forward_years || 0,
      status: leaveTypeData?.status ?? 1,
      description: leaveTypeData?.description || "",
      restrictPriorApplication: !!leaveTypeData?.restrict_prior_application,
      priorDays: leaveTypeData?.prior_days || 30,
      canRequestByEmployee: Number(leaveTypeData?.can_request_by_employee || 0),
      paid: Number(leaveTypeData?.paid || 0),
      isLop: Number(leaveTypeData?.is_lop || 0),
      gender: isEmptyGenderValues.includes(leaveTypeData?.gender)
        ? null
        : leaveTypeData?.gender,

      isAutoIncrementEnable: Number(
        leaveTypeData?.is_auto_increment_enabled || 0
      ),
      autoIncrementValue: leaveTypeData?.auto_increment_value || 1,
      includeHolidays: !!leaveTypeData?.include_holidays,
      includeNonWorkingDays: !!leaveTypeData?.include_non_working_days,
      isLive: leaveTypeData?.is_live,
      leaveModeIds: leaveTypeData?.leave_mode_ids?.length
        ? leaveTypeData.leave_mode_ids
        : isUpdateView
        ? leaveTypeData.leave_mode_ids || []
        : leaveModulesOptions?.map((module) => module.id) || [],
      isDocumentRequired: Number(leaveTypeData?.is_document_required || 0),
      maxDaysCanBeAccumulated: leaveTypeData?.max_limit_can_be_accumulated || "" ,
      minDaysForDocument: leaveTypeData?.min_days_for_document || "",
    },
  });

  const [isRestrictPriorApplication, setRestrictPriorApplication] = useState(
    !!leaveTypeData?.restrict_prior_application
  );
  const [isCanRequestByEmployee, setIsCanRequestByEmployee] = useState(
    Number(leaveTypeData?.can_request_by_employee || 0)
  );
  const [selectedModules, setSelectedModules] = useState(
    leaveTypeData?.leaveModulesApplicable || []
  );
  const getToggleState = (e, source) => {
    setValue(source, e);
  };

  useEffect(() => {
    if (!isUpdateView && leaveModulesOptions?.length) {
      // For adding a new leave type, pre-check all leave modules
      setValue(
        "leaveModeIds",
        leaveModulesOptions.map((module) => module.id)
      );
      setSelectedModules(leaveModulesOptions.map((module) => module.id));
    } else if (isUpdateView && leaveTypeData?.leave_mode_ids?.length) {
      setValue("leaveModeIds", leaveTypeData.leave_mode_ids);
      setSelectedModules(leaveTypeData.leave_mode_ids);
    }
  }, [leaveModulesOptions, leaveTypeData, isUpdateView, setValue]);

  const handleModuleChange = (optionId) => {
    const updatedModules = getValues("leaveModeIds").includes(optionId)
      ? getValues("leaveModeIds").filter((id) => id !== optionId)
      : [...getValues("leaveModeIds"), optionId];

    if (updatedModules.length === 0) {
      toast.info("At least one leave mode must be selected.");
      return;
    }

    setValue("leaveModeIds", updatedModules, { shouldValidate: true });
    setSelectedModules(updatedModules);
  };

  const onSubmit = async (data) => {
    const validLeaveModeIds = (data.leaveModeIds || []).filter(
      (id) => id !== null && id !== undefined
    );

    // Validation for leaveModeIds
    if (validLeaveModeIds.length === 0) {
      toast.error("Please select at least one leave mode.");
      return;
    }
    const payload = {
      leaveTypeName: data.leaveTypeName,
      numberOfDaysAllowed: Number(data.numberOfDaysAllowed || 0),
      carryForwardYears: Number(data.carryForwardYears || 0),
      status: Number(data.status),
      description: data.description,
      carryForwardLimit: Number(data.carryForwardLimit || 0),
      carryForward: Number(data.carryForward),
      carryForwardType: Number(data.carryForwardType),
      restrictPriorApplication: data.restrictPriorApplication,
      priorDays: Number(data.priorDays || 0),
      canRequestByEmployee: data.canRequestByEmployee ? 1 : 0,
      paid: data.paid,
      isLop: Boolean(data.isLop),
      gender: data.gender,
      isAutoIncrementEnable: Number(data.isAutoIncrementEnable),
      autoIncrementValue: Number(data.autoIncrementValue || 0),
      includeHolidays: data.includeHolidays,
      includeNonWorkingDays: data.includeNonWorkingDays,
      isLive: Number(data.isLive),
      leaveModeIds: selectedModules.filter((id) => id !== null),
      isDocumentRequired: Number(data.isDocumentRequired),
      maxDaysCanBeAccumulated: Number(data.maxDaysCanBeAccumulated || 0),
      minDaysForDocument: Number(data.minDaysForDocument || 0),
    };

    try {
      const response = isUpdateView
        ? await updateLeaveTypeMutation.mutateAsync(
            { ...payload, leaveTypeId: leaveTypeData.id },
          )
        : await createLeaveTypeMutation.mutateAsync(payload);
    
  
      if (response.status === false) {
        toast.error(response.message || "Creation failed, please try again.");
      } else {
        toast.success(response?.message);
        onCloseModal();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message || "Something went wrong, try again.");
    }
    
  };

  const getErrorMessage = (key) => {
    if (errors[key]) {
      return (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-2 ml-1">
          {errors[key].message}
        </span>
      );
    }
  };

  const handleCarryForwardType = (e) => {
    setSelectedCarryForwardType(e);
    setValue("carryForwardType", e.value);
  };

 
  const [infoVisible, setInfoVisible] = useState({});

  const handleFocus = (fieldName) => {
    setInfoVisible((prev) => ({ ...prev, [fieldName]: true }));
  };

  const handleBlur = (fieldName) => {
    setInfoVisible((prev) => ({ ...prev, [fieldName]: false }));
  };
  return (
    <>
      <ReactSlidingPane
        isOpen={openModal}
        onRequestClose={onCloseModal}
        width="450px"
      >
        <div className="max-w-[450px] text-black flex flex-col gap-3 h-full">
          <div className="flex justify-between items-center px-5 border-b py-3">
            <h1 className="font-medium">
              {isUpdateView ? "Update" : "Add"} Leave Type
            </h1>
            <button onClick={onCloseModal}>{closeIcon}</button>
          </div>
          <form
            className="flex flex-col justify-between h-full"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="px-5">
              <div className="py-3">
                <label className="py-1">Leave type name</label>
                <input
                  className="mt-1.5"
                  type="text"
                  {...register("leaveTypeName", {
                    required: "This field is required",
                  })}
                />
                {getErrorMessage("leaveTypeName")}
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <label className="py-3">is LOP (Loss of Pay)</label>
                  <input
                    type="checkbox"
                    {...register("isLop")}
                    onChange={(e) => setIsLop(e.target.checked)}
                  />
                </div>
              </div>
              {isLop ? (
                <FormFeildInfo info="This leave type is defined as Loss of Pay due to deduction in salary for leave taken by an employee." />
              ) : null}

              {!isLop ? (
                <>
                  {/* max days can be accumulated */}
                  <div className="py-1">
                    <label className="py-3">Max days can be accumulated</label>
                    <input
                      className="mt-1.5"
                      type="text"
                      {...register("maxDaysCanBeAccumulated", {
                        required: "This field is required",
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Please enter a valid number",
                        },
                      })}
                      onFocus={() => handleFocus("maxDaysCanBeAccumulated")}
                      onBlur={() => handleBlur("maxDaysCanBeAccumulated")}
                    />
                    {getErrorMessage("maxDaysCanBeAccumulated") && (
                      <p className="error">
                        {getErrorMessage("maxDaysCanBeAccumulated")}
                      </p>
                    )}
                    {infoVisible.maxDaysCanBeAccumulated && (
                      <div className="mt-2">
                        <FormFeildInfo info="Specify the maximum number of leave days that employees are allowed to accumulate over time. This must be a whole number." />
                      </div>
                    )}
                  </div>
                  {/* Total allocated leave days */}
                  <div className="py-1">
                    <label className="py-3">Total allocated leave days</label>
                    <input
                      className="mt-1.5"
                      type="text"
                      {...register("numberOfDaysAllowed", {
                        required: "This field is required",
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "Please enter a valid number",
                        },
                        validate: (value) => {
                          const maxDays = getValues("maxDaysCanBeAccumulated");
                          return (
                            parseInt(value) <= parseInt(maxDays) ||
                            `Allocated leave days cannot be greater than the allowed maximum of ${maxDays || 0} days`
                          );
                        },
                      })}
                      onFocus={() => handleFocus("numberOfDaysAllowed")}
                      onBlur={() => handleBlur("numberOfDaysAllowed")}
                    />
                    {getErrorMessage("numberOfDaysAllowed") && (
                      <p className="error">
                        {getErrorMessage("numberOfDaysAllowed")}
                      </p>
                    )}
                    {infoVisible.numberOfDaysAllowed && (
                      <div className="mt-2">
                        <FormFeildInfo info="Define the total number of leave days employees are allocated. This value cannot exceed the maximum accumulated days." />
                      </div>
                    )}
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <label className="py-3">Carry forward</label>
                      <input
                        type="checkbox"
                        {...register("carryForward")}
                        onChange={(e) => setCarryForward(e.target.checked)}
                      />
                    </div>
                  </div>
                </>
              ) : null}

              {isCarryForward ? (
                <>
                  <div className="flex flex-col gap-2">
                    <div>
                      <label>Carry forward type</label>
                      <Select
                        components={{ DropdownIndicator }}
                        className="custom-select-container"
                        options={carryForwardTypeOptions}
                        value={selectedCarryForwardType}
                        onChange={(e) => handleCarryForwardType(e)}
                      />
                      <div className="mt-2">
                        {selectedCarryForwardType?.value ===
                          numberOfDaysCarryForwrdOption && (
                          <FormFeildInfo info="The carry forward limit is set as a fixed number of days that can be transferred to the next year. Please specify a valid whole number for the limit." />
                        )}
                        {selectedCarryForwardType?.value ===
                          percentageTypeCarryForwrdOption && (
                          <FormFeildInfo info="The carry forward limit is calculated as a percentage of the unused leave balance. Enter a value between 0 and 100% to define the limit." />
                        )}
                        {!selectedCarryForwardType && (
                          <FormFeildInfo info="Carry forward can be configured as either a fixed number of days or a percentage of the unused balance. Please select a type to continue." />
                        )}
                      </div>
                    </div>

                    <div>
                      <label>Carry forward limit</label>
                      <input
                        type="text"
                        {...register("carryForwardLimit", {
                          required: "This field is required",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a valid number",
                          },
                        })}
                      />
                      {getErrorMessage("carryForwardLimit")}
                    </div>

                    <div>
                      <label>Carry forward years</label>
                      <input
                        type="text"
                        {...register("carryForwardYears", {
                          required: "This field is required",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a valid number",
                          },
                        })}
                      />
                      {getErrorMessage("carryForwardYears")}
                    </div>
                  </div>
                </>
              ) : null}

              {!isLop ? (
                <>
                  <div className="py-2">
                    <div className="flex items-center gap-2 justify-between max-w-sm">
                      {" "}
                      Paid
                      <ToggleSwitch
                        checked={getValues("paid")}
                        getToggleState={(e) => setValue("paid", e ? 1 : 0)}
                      />
                    </div>
                  </div>
                </>
              ) : null}
              <div className="py-3">
                <div className="flex items-center gap-2 justify-between max-w-sm ">
                  {" "}
                  Enable auto increment
                  <ToggleSwitch
                    checked={isAutoIncrement}
                    getToggleState={(e) => {
                      setIsAutoIncrement(e);
                      setValue("isAutoIncrementEnable", Number(e));
                      if (!e) {
                        setValue(
                          "autoIncrementValue",
                          getValues("autoIncrementValue")
                        );
                      }
                    }}
                  />
                </div>
              </div>
              {!!Number(isAutoIncrement) && (
                <div className="py-3 max-w-sm">
                  <label>Auto increment value</label>
                  <input
                    type="text"
                    {...register("autoIncrementValue", {
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Please enter a valid number",
                      },
                    })}
                  />
                  {getErrorMessage("autoIncrementValue")}
                </div>
              )}

              <div className=" flex flex-col gap-2">
                <div className="flex items-center gap-2 justify-between max-w-sm py-3">
                  Active
                  <ToggleSwitch
                    checked={Number(getValues("status"))}
                    getToggleState={(e) => getToggleState(e, "status")}
                  />
                </div>
                <div className="flex items-center gap-2 justify-between max-w-sm  py-3">
                  Include holidays
                  <ToggleSwitch
                    checked={getValues("includeHolidays")}
                    getToggleState={(e) => getToggleState(e, "includeHolidays")}
                  />
                </div>
                <div className="flex items-center gap-2 justify-between max-w-sm py-3">
                  Include non working days
                  <ToggleSwitch
                    checked={getValues("includeNonWorkingDays")}
                    getToggleState={(e) =>
                      getToggleState(e, "includeNonWorkingDays")
                    }
                  />
                </div>
                <div className="flex items-center gap-2 justify-between max-w-sm  py-3">
                  Is Live
                  <ToggleSwitch
                    checked={getValues("isLive")}
                    getToggleState={(e) => getToggleState(e, "isLive")}
                  />
                </div>
              </div>
              <div>
                <label>Leave Mode Applicable</label>
                {isLeaveModulesLoading ? (
                  <p>Loading modes...</p>
                ) : (
                  <div className="flex flex-wrap gap-4 mt-2">
                    {leaveModulesOptions.map((option) => (
                      <div key={option.id} className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          value={option.id}
                          checked={getValues("leaveModeIds").includes(
                            option.id
                          )}
                          onChange={() => handleModuleChange(option.id)}
                        />
                        <label>{option.mode_type}</label>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="pt-3 flex items-center gap-3">
                <label>Restrict prior application</label>
                <input
                  type="checkbox"
                  {...register("restrictPriorApplication")}
                  onChange={(e) =>
                    setRestrictPriorApplication(e.target.checked)
                  }
                />
              </div>
              {/* Info below Restrict prior application checkbox */}
              {!!Number(isRestrictPriorApplication) && (
                <div
                  className="p-2 text-xs text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 mt-2"
                  role="alert"
                >
                  <FormFeildInfo info="Employee can only apply leave for prior dates" />
                </div>
              )}
              {/* Additional leave request option (conditional rendering based on isLop) */}
              {!isLop && (
                <div className="flex pt-3 items-center gap-3">
                  <label>Can request by employee for additional leave</label>
                  <input
                    type="checkbox"
                    {...register("canRequestByEmployee")}
                    onChange={(e) =>
                      setIsCanRequestByEmployee(e.target.checked)
                    }
                  />
                </div>
              )}

              {/* Info below the additional leave request checkbox */}
              {!!Number(isCanRequestByEmployee) && (
                <div className="mt-2">
                  <FormFeildInfo info="Employee can apply additional leave for this leave type if they required." />
                </div>
              )}
              {/* Document required */}

              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <label className="py-3">
                    Employee needs to provide document
                  </label>
                  <input
                    type="checkbox"
                    {...register("isDocumentRequired")}
                    checked={isDocumentRequired === 1}
                    onChange={(e) =>
                      setIsDocumentRequired(e.target.checked ? 1 : 0)
                    }
                  />
                </div>
              </div>

              {/* Conditionally render info below the checkbox if it's checked */}
              {!!isDocumentRequired && (
                <div className="mt-2">
                  <FormFeildInfo info="Enable this option if employees must provide a supporting document, such as a medical certificate or approval letter, when applying for leave." />
                </div>
              )}

              {/* Min_days_for_document */}
              {!!isDocumentRequired && (
                <div className="py-3 max-w-sm">
                  <label>Min days for document</label>
                  <input
                    type="text"
                    defaultValue={0}
                    {...register("minDaysForDocument", {
                      valueAsNumber: true,
                      validate: (value) =>
                        value >= 0 || "Value must be 0 or greater",
                      setValueAs: (value) => (value === "" ? 0 : value),
                    })}
                    onFocus={() => handleFocus("minDaysForDocument")}
                    onBlur={() => handleBlur("minDaysForDocument")}
                  />
                  {getErrorMessage("minDaysForDocument")}
                  {infoVisible.minDaysForDocument && (
                    <FormFeildInfo info="Specify the minimum number of leave days after which employees must provide a supporting document." />
                  )}
                </div>
              )}

              {/* gender */}
              <div className="py-3">
                <label>Applicable for</label>
                <select
                  className="mt-1.5"
                  {...register("gender")}
                  onChange={(e) => {
                    const value = e.target.value === "" ? null : e.target.value;
                    setValue("gender", value);
                  }}
                  defaultValue={
                    isEmptyGenderValues.includes(leaveTypeData?.gender)
                      ? ""
                      : leaveTypeData?.gender
                  }
                >
                  <option value="">All Gender</option>
                  {genderOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="py-3">
                <label>Prior days</label>
                <input
                  className="mt-1.5"
                  type="text"
                  {...register("priorDays", {
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a valid number",
                    },
                  })}
                />
              </div>

              <div className="pb-3">
                <label className="py-3">Description</label>
                <Controller
                  control={control}
                  name="description"
                  rules={{
                    required: "This field is required",
                  }}
                  render={({ field }) => {
                    return (
                      <ReactQuill className="mt-1.5" {...field} theme="snow" />
                    );
                  }}
                />
              </div>

              {getErrorMessage("description")}
            </div>

            <div className="flex justify-end gap-4 border-t py-3 px-5">
              <button
                type="button"
                className="btn btn--border"
                onClick={onCloseModal}
              >
                Cancel
              </button>
              <button type="submit" className="btn">
                {isUpdateView ? "Update" : "Add"} leave type
              </button>
            </div>
          </form>
        </div>
      </ReactSlidingPane>
    </>
  );
};

export default AddOrEditLeaveTypeModal;
