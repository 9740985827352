import React, { useState, useEffect } from "react";
import LeaveTypeListData from "./LeaveTypeListData";
import { useUpdateLeaveTypeStatus } from "../../../../queries/leaveQueries";
import { toast } from "react-toastify";
const LeaveTypeListTable = ({ leaveTypes }) => {

    const [leaveTypesState, setLeaveTypesState] = useState(leaveTypes);
    const { mutate: updateLeaveTypeStatus } = useUpdateLeaveTypeStatus();
    useEffect(() => {
      setLeaveTypesState(leaveTypes); 
    }, [leaveTypes]);
  
    const handleStatusToggle = async (id, newStatus) => {
      try {
     
        setLeaveTypesState((prev) =>
          prev.map((item) =>
            item.id === id ? { ...item, status: newStatus } : item
          )
        );
  
        await updateLeaveTypeStatus({ id, status: newStatus ? 1 : 0 });
        toast.success("Leave type status updated successfully!");
      } catch (error) {
   
        setLeaveTypesState((prev) =>
          prev.map((item) =>
            item.id === id ? { ...item, status: !newStatus } : item
          )
        );
        toast.error("Error updating leave type status.");
      }
    };
  return (
    <div class="relative px-10 pb-10">
      {leaveTypes &&
        leaveTypes.map((val, key) => {
          return (
            <LeaveTypeListData
            requestdata={val}
            key={val.id} 
            onToggleStatus={handleStatusToggle} 
          />
          );
        })}
    </div>
  );
};

export default LeaveTypeListTable;
