import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Line from "../../../../Line";
import Select, { components } from "react-select";
import { useForm } from "react-hook-form";
import ToggleSwitch from "../../../../ToggleSwitch/ToggleSwitch";
import { carryForwardTypeOptions } from "../../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import { Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  numberRegex,
  cannotStartWithSpace,
} from "../../../../../utils/regex/regex";
import FormFeildInfo from "../../../../FormFeildInfo/FormFeildInfo";
import { infoIcon } from "../../../../../Icons/Icons";
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};

const EditLeaveTypesInPolicyModal = ({
  openModal,
  onCloseModal,
  leave,
  setPolicyData,
  policyData,
}) => {
  const numberOfDaysCarryForwrdOption = 1;
  const percentageTypeCarryForwrdOption = 2;
  const draftData = policyData.find(
    (i) => Number(i.leave_type_id) === Number(leave.leave_type_id)
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    mode: "onBlur",
    defaultValues: draftData
      ? draftData
      : {
          leave_type_id: Number(leave.leave_type_id),
          leave_name: leave.leave_name,
          number_days_allowed: leave.number_days_allowed,
          description: leave.description,
          carry_forward: Number(leave.carry_forward),
          carry_forward_type: Number(leave.carry_forward_type),
          carry_forward_limit: Number(leave.carry_forward_limit),
          carry_forward_years: leave.carry_forward_years,
          status: Number(leave.status),
        },
  });

  const [isActive, setIsActive] = useState(
    draftData ? Number(draftData.status) : Number(leave.status)
  );
  const [isCarryForward, setIsCarryForward] = useState(
    draftData ? Number(draftData.carry_forward) : Number(leave.carry_forward)
  );
  const [selectedCarryForwardType, setSelectedCarryForwardType] = useState(
    draftData
      ? Number(draftData.carry_forward_type)
      : Number(leave.carry_forward_type)
  );

  const getToggleState = (e) => {
    setValue("status", Number(e));
    setIsActive(e);
  };

  const onSubmit = async (data) => {
    const {
      carry_forward,
      carry_forward_limit,
      carry_forward_type,
      carry_forward_years,
      description,
      leave_name,
      number_days_allowed,
      status,
    } = data;

    const isDataChanged = Object.keys(data).some((k) => {
      const isDataNan = Number.isNaN(Number(data[k]));
      const isLeaveDataNan = Number.isNaN(Number(leave[k]));
      if (isDataNan && isLeaveDataNan) {
        return data[k] !== leave[k];
      }
      return Number(data[k]) !== Number(leave[k]);
    });

    if (isDataChanged) {
      setPolicyData((prev) => {
        const isExists = prev?.find?.(
          (i) => Number(i?.leave_type_id) === Number(leave.leave_type_id)
        );
        if (isExists) {
          return prev.map((item) => {
            if (Number(item.leave_type_id) === Number(leave.leave_type_id)) {
              return {
                leave_type_id: Number(item.leave_type_id),
                leave_name,
                number_days_allowed: Number(number_days_allowed),
                description,
                carry_forward: Number(carry_forward),
                carry_forward_type: Number(carry_forward_type),
                carry_forward_limit: Number(carry_forward_limit),
                carry_forward_years: Number(carry_forward_years),
                status: Number(status),
                draftted: true,
              };
            } else {
              return item;
            }
          });
        } else {
          return [
            ...prev,
            {
              leave_type_id: Number(leave.leave_type_id),
              leave_name,
              number_days_allowed: Number(number_days_allowed),
              description,
              carry_forward: Number(carry_forward),
              carry_forward_type: Number(carry_forward_type),
              carry_forward_limit: Number(carry_forward_limit),
              carry_forward_years: Number(carry_forward_years),
              status: Number(status),
              draftted: true,
            },
          ];
        }
      });
    }

    onCloseModal();
  };

  const handleChangeCarryForwardType = (e) => {
    setSelectedCarryForwardType(e?.value);
    setValue("carry_forward_type", e?.value);
  };
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  const openInfoModal = () => setInfoModalOpen(true);
  const closeInfoModal = () => setInfoModalOpen(false);
  return (
    <>
      <Modal open={openModal} onClose={onCloseModal} center>
        <form onSubmit={handleSubmit(onSubmit)} className="min-w-[500px]">
          <div className="p-5 max-w-[450px] text-black flex flex-col gap-3">
            <div className="text-lg">
              <label>Draft leave details</label>
              <Line />
            </div>
            <div>
              <p className="font-medium text-lg"> {leave.leave_name}</p>
            </div>
            {!leave.is_lop ? (
              <div>
              <label className="py-3 text-gray-600 flex items-center gap-1">
                Total allocated leave days
                <span className="cursor-pointer" onClick={openInfoModal}>
                  {infoIcon}
                </span>
              </label>
              <input
                type="text"
                {...register("number_days_allowed", {
                  required: "This field is required",
                  pattern: {
                    value: numberRegex,
                    message: "Please enter a valid number",
                  },
                  validate: (value) => {
                    const maxDays = leave.max_limit_can_be_accumulated;
                    return (
                      parseInt(value) <= parseInt(maxDays) ||
                      `Allocated leave days cannot be greater than the allowed maximum of days accumulated i.e:${maxDays || 0} days`
                    );
                  },
                })}
              />
              {errors?.number_days_allowed && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-2 ml-1">
                  {errors.number_days_allowed.message}
                </span>
              )}
            </div>
            
            ) : null}
            <Modal open={infoModalOpen} onClose={closeInfoModal} center>
              <div className="p-4">
                <p>
                  <span>Maximum days can be accumulated: </span>
                  <span style={{ fontWeight: "600" }}>
                    {leave.max_limit_can_be_accumulated || 0}
                  </span>
                </p>
              </div>
            </Modal>
            {!leave.is_lop ? (
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <label className="py-3 text-gray-600">Carry forward</label>
                  <input
                    type="checkbox"
                    {...register("carry_forward")}
                    onChange={(e) => setIsCarryForward(e.target.checked)}
                  />
                </div>
              </div>
            ) : null}

            {isCarryForward ? (
              <div className="flex flex-col gap-2">
                <div>
                  <label className="text-gray-600">Carry forward type</label>
                  <Select
                    components={{ DropdownIndicator }}
                    className="custom-select-container"
                    options={carryForwardTypeOptions}
                    defaultValue={carryForwardTypeOptions.find(
                      (i) => Number(i?.value) === selectedCarryForwardType
                    )}
                    onChange={handleChangeCarryForwardType}
                  />
                  <div className="mt-2">
                    {selectedCarryForwardType ===
                      numberOfDaysCarryForwrdOption && (
                      <FormFeildInfo info="The carry forward limit is set as a fixed number of days that can be transferred to the next year. Please specify a valid whole number for the limit." />
                    )}
                    {selectedCarryForwardType ===
                      percentageTypeCarryForwrdOption && (
                      <FormFeildInfo info="The carry forward limit is calculated as a percentage of the unused leave balance. Enter a value between 0 and 100% to define the limit." />
                    )}
                    {!selectedCarryForwardType && (
                      <FormFeildInfo info="Carry forward can be configured as either a fixed number of days or a percentage of the unused balance. Please select a type to continue." />
                    )}
                  </div>
                  <span
                    id="carry_forward_type"
                    className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1"
                  ></span>
                </div>
                <div>
                  <label className="text-gray-600">Carry forward limit</label>
                  <input
                    type="text"
                    {...register("carry_forward_limit", {
                      required: "This field is required",
                      pattern: {
                        value: numberRegex,
                        message: "Please enter a valid number",
                      },
                    })}
                  />
                  {errors?.carry_forward_limit && (
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-2 ml-1">
                      {errors.carry_forward_limit.message}
                    </span>
                  )}
                </div>
                <div>
                  <label className="text-gray-600">Carry forward years</label>
                  <input
                    type="text"
                    {...register("carry_forward_years", {
                      required: "This field is required",
                      pattern: {
                        value: numberRegex,
                        message: "Please enter a valid number",
                      },
                    })}
                  />
                  {errors?.carry_forward_years && (
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-2 ml-1">
                      {errors.carry_forward_years.message}
                    </span>
                  )}
                </div>
              </div>
            ) : null}

            <div className="flex w-fit items-center gap-3 justify-between min-w-[120px] ">
              <span className={`status status--${isActive ? "green" : "red"}`}>
                {isActive ? "Active" : "Inactive"}
              </span>
              <ToggleSwitch
                checked={isActive}
                getToggleState={getToggleState}
              />
            </div>
            <div className="pb-3">
              <label className="py-3 text-gray-600">Description</label>

              <Controller
                control={control}
                name="description"
                rules={{
                  required: "This field is required",
                  pattern: {
                    value: cannotStartWithSpace,
                    message: "Cannot start with space",
                  },
                }}
                render={({ field }) => (
                  <ReactQuill
                    {...field}
                    theme="snow"
                    className="mt-1.5"
                    placeholder="Enter the description here..."
                  />
                )}
              />

              {errors?.description && (
                <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-2 ml-1">
                  {errors.description.message}
                </span>
              )}
            </div>
            <div className="flex justify-end gap-4 mt-10">
              <button
                type="button"
                className="btn btn--border"
                onClick={onCloseModal}
              >
                Cancel
              </button>
              <button type="submit" className="btn">
                Draft
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default EditLeaveTypesInPolicyModal;
