import React, { useState } from "react";
import Modal from "react-responsive-modal";
import { closeIcon } from "../../Icons/Icons";
import { useForm, Controller } from "react-hook-form";
import Select, { components } from "react-select";
import { useGetRequestDocumentTypeList, useRequestEmployeeDocument } from "../../queries/systemPolicyQueries";
import {cannotStartWithSpace} from "../../utils/regex/regex"
import { toast } from "react-toastify";

const RequestDocument = ({ isModalOpen, handleCloseModal }) => {
  const { data: getDocumentList } = useGetRequestDocumentTypeList({});
  const requestDocumentMutation = useRequestEmployeeDocument();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const documentOptions =
    getDocumentList?.data?.map((doc) => ({
      value: doc.id,
      label: doc.document_type,
    })) || [];

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({ mode: "onBlur" });

  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );

  const onSubmit = (formData) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
  
    const payload = {
      document_type_id: formData.document_type.value,
      subject: formData.subject,
      description: formData.description,
    };
  
    requestDocumentMutation.mutate(payload, {
      onSuccess: () => {
        toast.success("Document request submitted successfully!");
        reset();
        handleCloseModal();
        setIsSubmitting(false);
      },
      onError: (error) => {
        console.error("Error submitting document request:", error);
        toast.error("Failed to submit document request. Please try again.");
        setIsSubmitting(false);
      },
    });
  };;

  return (
    <Modal
      open={isModalOpen}
      closeOnEsc={false}
      onClose={handleCloseModal}
      showCloseIcon={false}
      center
      closeOnOverlayClick={false}
    >
      <div className="w-[500px] flex flex-col gap-3">
        <div className="flex justify-between items-center">
          <h1 className="font-medium text-xl text-gray-800">Request Document</h1>
          <button onClick={handleCloseModal}>{closeIcon}</button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3">
          <div>
            <label>Type <span className="text-red-600 font-medium">*</span></label>
            <Controller
              name="document_type"
              control={control}
              rules={{ required: "Document type is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={documentOptions}
                  components={{ DropdownIndicator }}
                  className="custom-select-container text-gray-500"
                  placeholder="Select Document"
                />
              )}
            />
            {errors.document_type && <p className="text-red-500">{errors.document_type.message}</p>}
          </div>

          <div>
            <label>Subject <span className="text-red-600 font-medium">*</span></label>
            <input
              {...register("subject", {
                required: "Subject is required",
               pattern: {
                                        value: cannotStartWithSpace,
                                        message: "Cannot start with a space",
                                      },
              })}
              type="text"
              className="input-field"
            />
            {errors.subject && <p className="text-red-500">{errors.subject.message}</p>}
          </div>

          <div>
            <label>Description <span className="text-red-600 font-medium">*</span></label>
            <textarea
              {...register("description", {
                required: "Description is required",
                pattern: { value: /^\S.*/, message: "Cannot start with space" },
              })}
              className="input-field"
            ></textarea>
            {errors.description && <p className="text-red-500">{errors.description.message}</p>}
          </div>

          <div className="flex justify-end gap-3">
          <button
              type="button"
              onClick={handleCloseModal}
              className="btn btn--border"
              disabled={isSubmitting}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className={`btn bg-blue-600 text-white px-4 py-2 rounded ${
                isSubmitting ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isSubmitting ? "Requesting..." : "Request"}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default RequestDocument;
