import React, { useState } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import DocumentListData from "../../components/Document/DocumentListData";
import Pagination from "../../components/Pagination/Pagination";
import Select from "react-select";
import Search from "../../components/Header/Search";
import { useSelector } from "react-redux";
import userRoleAuth from "../../utils/userRoleAuth/userRoleAuth";
import userRoles from "../../config/userRoles";
import RequestDocument from "../../components/Document/RequestDocumet";
import {
  useGetEmployeeRequestDocumenListData,
  useGetEmployeeRequestDocumenHRListData,
} from "../../queries/systemPolicyQueries";
import { documentApprovalStatusOptions } from "../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import moment from "moment";

const DocumentList = () => {
  const loggedInUser = useSelector((state) => state.user.auth);
  const isHr = userRoleAuth(loggedInUser, userRoles.HR);
  const isAdmin = userRoleAuth(loggedInUser, userRoles.TENANTADMIN);
  const isEmployee = userRoleAuth(loggedInUser, userRoles.EMPLOYEE);
  const isManager = userRoleAuth(loggedInUser, userRoles.MANAGER);

  const isAdminOrHR = isHr || isAdmin;
  const isEmployeeOrManager = isEmployee || isManager;

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [approvalStatus, setApprovalStatus] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [requestDate, setRequestDate] = useState(null);

  // Construct API parameters
  const apiParams = {
    page_number: pageNo,
    page_limit: pageSize,
    approval_status_id: approvalStatus,
    created_date: requestDate
      ? moment(requestDate).format("YYYY-MM-DD")
      : undefined,
    filter: searchValue,
  };

  const { data: employeeDocument } =
    useGetEmployeeRequestDocumenListData(apiParams);
  const { data: employeeDocumentHRView } =
    useGetEmployeeRequestDocumenHRListData(apiParams);

  const totalRecords = isAdminOrHR
    ? employeeDocumentHRView?.totalRecords ?? 0
    : employeeDocument?.totalRecords ?? 0;

  const [isCreateDocumentModalOpen, setIsCreateDocumentModalOpen] =
    useState(false);

  return (
    <div className="w-full overflow-x-auto">
      {isCreateDocumentModalOpen && (
        <RequestDocument
          isModalOpen={isCreateDocumentModalOpen}
          handleCloseModal={() => setIsCreateDocumentModalOpen(false)}
        />
      )}

      <div className="px-10 py-6">
        <div className="my-2 flex items-center justify-between">
          <div>
            <h2 className="text-lg font-semibold">Document</h2>
            <p>View all documents</p>
          </div>
          {isEmployeeOrManager && (
            <button
              className="btn"
              onClick={() => setIsCreateDocumentModalOpen(true)}
            >
              New Request
            </button>
          )}
        </div>
      </div>

      <div className="py-3 flex justify-between gap-3 px-10">
        <div className="flex gap-3">
          <Select
            isClearable
            placeholder="Approval Status"
            options={documentApprovalStatusOptions}
            onChange={(e) => setApprovalStatus(e?.value)}
          />

          <DatePicker
            placeholder="Requested Date"
            onChange={(date) =>
              setRequestDate(date ? dayjs(date).format("YYYY-MM-DD") : null)
            }
            value={requestDate ? dayjs(requestDate) : null}
          />
        </div>

        <div>
          {isAdminOrHR && <Search mx="0" onChange={(e) => setSearchValue(e)} />}
        </div>
      </div>
      <DocumentListData
        employeeRequestList={
          isAdminOrHR ? employeeDocumentHRView : employeeDocument
        }
      />

      {(isAdminOrHR ? employeeDocumentHRView : employeeDocument) && (
        <Pagination
          page={{
            pageNo,
            setPageNo,
            pageSize,
            setPageSize,
            totalRecords,
          }}
        />
      )}
    </div>
  );
};

export default DocumentList;
