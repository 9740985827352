import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { formatSearchParams } from "../utils/helperFunctions";
import queryKeys from "./queryKeys";

const {
  getSystemPolicyList,
  createSystemPolicyList,
  getSystemPolicyAttachments,
  getSystemPolicyListForSelectBox,
  updateSystemPolicyList,
  systemPolicyApproval,
  updateSystemPolicyStatus,
  getEmployeeViewListSystemPolicy,
  updateEmployeeSystemPolicyViewStatus,
  getRequestDocumentTypeList,
  getEmployeeRequestDocumentTypeListData,
  requestEmployeeDocument,
  getEmployeeRequestDocumentTypeHRListData,
  hrRejectDocument,hrAcceptDocument,
  uploadEmployeeDocument,
  adminDocumentApproval,
  adminRejectDocument,
} = queryKeys;

export const useGetSystemPolicyList = (params = {}) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [getSystemPolicyList, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "employee/system-policy/system-policy-list",
        {
          params: formattedParams,
        }
      );
      return data;
    },
  });
};
export const useGetSystemPolicyListForSelectBox = (params = {}, enabled) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [getSystemPolicyListForSelectBox],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "employee/system-policy/system-policy-list",
        {
          params: {
            ...formattedParams,
            selectbox: 1,
          },
        }
      );
      return data;
    },
    enabled,
  });
};

export const useGetSystemPolicyAttachments = (params = {}) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [getSystemPolicyAttachments],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "employee/system-policy/system-policy-attachments",
        {
          params: formattedParams,
        }
      );
      return data;
    },
    enabled: !!formattedParams?.system_policy,
  });
};

export const useCreateSystemPolicy = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [createSystemPolicyList],
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.post(
        "employee/system-policy/create-policy",
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([getSystemPolicyList]);
      }
    },
    onError: () => {
      // handle error
    },
  });
};

export const useUpdateSystemPolicy = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [updateSystemPolicyList],
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.put(
        "employee/system-policy/update-policy",
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([getSystemPolicyList]);
      }
    },
    onError: () => {
      // handle error
    },
  });
};
export const useSystemPolicyApproval = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [systemPolicyApproval],
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.post(
        "employee/system-policy/policy-approval",
        payload
      );
      return data;
    },
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([getSystemPolicyList]);
      }
    },
    onError: () => {
      // handle error
    },
  });
};
export const useUpdateSystemPolicyStatus = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [updateSystemPolicyStatus],
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.put(
        "employee/system-policy/update-policy-status",
        payload
      );
      return data;
    },
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([getSystemPolicyList]);
      }
    },
    onError: () => {
      // handle error
    },
  });
};

export const useGetEmployeeSystemPolicyViewList = (params = {}) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [getEmployeeViewListSystemPolicy, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "employee/system-policy/employee-policy-view",
        {
          params: formattedParams,
        }
      );
      return data;
    },
  });
};
export const useUpdateEmployeeSystemPolicyViewStatus = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [updateEmployeeSystemPolicyViewStatus],
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.put(
        "employee/system-policy/update-employee-view-status",
        payload
      );
      return data;
    },
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([getEmployeeViewListSystemPolicy]);
      }
    },
    onError: () => {
      // handle error
    },
  });
};

export const useGetRequestDocumentTypeList = (params = {}) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [getRequestDocumentTypeList, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "employee/system-policy/documet-type-list",
        {
          params: formattedParams,
        }
      );
      return data;
    },
  });
};
export const useGetEmployeeRequestDocumenListData = (params = {}) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [getEmployeeRequestDocumentTypeListData, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "employee/system-policy/employee-request-list",
        {
          params: formattedParams,
        }
      );
      return data;
    },
  });
};

export const useRequestEmployeeDocument = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [requestEmployeeDocument],
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.post(
        "employee/system-policy/request-document",
        payload,
      );
      return data;
    },
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([getEmployeeRequestDocumentTypeListData]);
      }
    },
    onError: () => {
      // handle error
    },
  });
};

export const useGetEmployeeRequestDocumenHRListData = (params = {}) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [getEmployeeRequestDocumentTypeHRListData, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "employee/system-policy/employee-request-hr-list",
        {
          params: formattedParams,
        }
      );
      return data;
    },
  });
};

export const useAcceptEmployeeRequestDocument = (params = {}) => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [hrAcceptDocument],
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.post(
        "employee/system-policy/hr-accept-employee-request",
        payload,
      );
      return data;
    },
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([getEmployeeRequestDocumentTypeHRListData]);
      }
    },
    onError: () => {
      // handle error
    },
  });
};

export const useRejectEmployeeRequestDocument = (params = {}) => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [hrRejectDocument],
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.post(
        "employee/system-policy/hr-reject-employee-request",
        payload,
      );
      return data;
    },
 
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([getEmployeeRequestDocumentTypeHRListData]);
      }
    },
    onError: () => {
      // handle error
    },
  });
};
export const useUploadEmployeeDocument = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [uploadEmployeeDocument],
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.post(
        "employee/system-policy/upload-employee-document",
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([getEmployeeRequestDocumentTypeHRListData]);
      }
    },
    onError: () => {
      // handle error
    },
  });
};

export const useGetEmployeeDocumetDetailsView = (params = {}) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [getEmployeeRequestDocumentTypeHRListData, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "employee/system-policy/employee-document-details",
        {
          params: formattedParams,
        }
      );
      return data;
    },
  });
};
export const useAdminDocumentApproval = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [adminDocumentApproval],
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.post(
        "employee/system-policy/admin-approve-employee-document",
        payload,
      
      );
      return data;
    },
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([getEmployeeRequestDocumentTypeHRListData]);
      }
    },
    onError: () => {
      // handle error
    },
  });
};
export const useAdminRejectEmployeeRequestDocument = (params = {}) => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [adminRejectDocument],
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.post(
        "employee/system-policy/admin-reject-employee-document",
        payload,
      );
      return data;
    },
 
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([getEmployeeRequestDocumentTypeHRListData]);
      }
    },
    onError: () => {
      // handle error
    },
  });
};