import React, { useState } from "react";
import LeaveSetting from "./LeaveSetting";
import DocumentSetting from "./DocumentSetting";

const AppSetting = () => {
  const [toggleState, setToggleState] = useState(0);
  const tabs = [
    { label: "LEAVE", component: <LeaveSetting /> },
    { label: "DOCUMENT", component: <DocumentSetting /> },
  ];

  return (
    <div className="p-5 w-full">
      <div className="py-3">
        <h1 className="text-xl font-medium text-gray-800">App Setting</h1>
      </div>
      <div className="flex gap-3 border-b">
        {tabs.map((tab, index) => (
          <button
            key={tab.label}
            className={`px-4 py-2 text-sm font-medium ${
              toggleState === index ? "border-b-2 border-blue-500 text-blue-600" : "text-gray-600"
            }`}
            onClick={() => setToggleState(index)}
            role="tab"
            aria-selected={toggleState === index}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="py-3">{tabs[toggleState].component}</div>
    </div>
  );
};

export default AppSetting;
