import React, { useState, useContext } from "react";
import Avatar from "../../components/Avatar/Avatar";
import LeaveDetailsModal from "./LeaveDetailsModal";
import formatDate from "../../utils/FormatDate/formatDate";
import formatLeaveDuration from "../../utils/FormatLeaveDuration/formatLeaveDuration";
import formatLeaveApplicationStatus from "../../utils/FormatLeaveApplicationStatus/formatLeaveApplicationStatus";
import { useSelector } from "react-redux";
import userRoleAuth from "../../utils/userRoleAuth/userRoleAuth";
import ApproveLeaveModal from "./LeaveApplicationActionModal";
import formatAvatarLetter from "../../utils/FormatAvatarLetter/formatAvatarLetters";
import { closeIconRoundRed, greenTickRound } from "../../Icons/Icons";
import { LeaveApprovalContext } from "./TaskLeaveApplications";
import userRoles from "../../config/userRoles";
import { LEAVE_MODAL_ACTIONS } from "../../Consts/consts";

const LeaveApplicationListData = ({ requestdata: application }) => {
  const user = useSelector((state) => state.user.auth);
  const isHr = userRoleAuth(user, userRoles.HR);
  const {
    isLeaveReqTab,
    isAdditionalLeaveReqTab,
    isEligibleForAction,
    handleSetSelectedIds,
    selectedIds,
  } = useContext(LeaveApprovalContext);

  const [details, setDetails] = useState(false);
  const [leaveActionModal, setLeaveActionModal] = useState({
    isOpen: false,
    action: "",
  });

  const handleSetModal = (modal, status) => {
    setLeaveActionModal((prev) => ({
      ...prev,
      isOpen: status,
      action: modal || "",
    }));
  };

  const leaveApplicationId = isAdditionalLeaveReqTab
    ? application?.additional_leave_application_id
    : isLeaveReqTab
    ? application?.leave_application_id
    : null;

  const applicationDate = formatDate(application?.application_date);

  const fromDate = formatDate(
    isLeaveReqTab ? application?.from_date : application?.applied_for_date
  );
  const toDate = formatDate(application?.to_date);
  const employeeName =
    application?.employee_name ||
    `${application?.first_name} ${application?.last_name}`;
  const status = formatLeaveApplicationStatus(
    isHr
      ? application?.leave_status || application?.hr_approval_status_label
      : application?.manager_approval_status_label
  );

  const avatarLetter = formatAvatarLetter(employeeName);
  const isCanceled = application?.is_canceled;
  const managerApprovalStatusId = application?.manager_approval_status_id;
  const isForceAction = application?.force_action;
  const depatment = application?.department_name;
  const employeeId = application?.employee_serial || "";

  const leaveSerial = application?.leave_serial || "";
  const leaveType = application?.leave_name;
  const durationInDay = application?.duration_in_day || 0;
  const durationInHour = application?.duration_in_hour || 0;
  const duration = formatLeaveDuration({ durationInDay, durationInHour });
  const LEVE_PEDNING_STATUS = "Pending";
  const LEVE_REJECT_STATUS = 3;

  const isChecked = !!selectedIds.find(
    (i) =>
      i?.[
        isLeaveReqTab
          ? "leave_application_id"
          : isAdditionalLeaveReqTab
          ? "additional_leave_application_id"
          : ""
      ] === leaveApplicationId
  );

  return (
    <>
      {leaveActionModal.isOpen ? (
        <ApproveLeaveModal
          openModal={leaveActionModal.isOpen}
          onCloseModal={() => handleSetModal("", false)}
          leaveApplications={application}
          action={leaveActionModal.action}
        />
      ) : null}
      {details ? (
        <LeaveDetailsModal
          openModal={details}
          onCloseModal={() => setDetails(false)}
          leaveApplicationId={leaveApplicationId}
        />
      ) : null}

      <table>
        <tbody>
          <tr className="bg-white border-b text-black hover:bg-gray-50 dark:hover:bg-gray-200 flex items-center">
            {isLeaveReqTab ? (
              <td className=" py-2 flex items-center gap-3 px-5  cursor-pointer w-[6%] border-0">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={(e) => {
                      handleSetSelectedIds(application, e.target.checked);
                    }}
                  />
                </div>
              </td>
            ) : null}

            <td
              onClick={() => setDetails(true)}
              className={`cursor-pointer py-2 ${
                isLeaveReqTab ? "w-[10%] px-5" : "w-[20%] px-10"
              } border-0`}
            >
              {leaveSerial}
            </td>
            <td
              className={` py-2 ${
                isLeaveReqTab ? "w-[14%] px-5" : "w-[20%] px-10"
              } border-0`}
            >
              <div className="flex items-center gap-2">
                <Avatar title={avatarLetter} />
                <div className="flex flex-col">
                  <p className="font-medium">{employeeName}</p>
                  <span className="text-sm text-gray-600 ">{employeeId}</span>
                </div>
              </div>
            </td>
            <td
              className={` py-2 ${
                isLeaveReqTab ? "w-[15%] px-5" : "w-[20%] px-10"
              } border-0`}
            >
              {leaveType}
              <br />
              {applicationDate.dateString2}
            </td>

            <td
              className={` py-2 ${
                isLeaveReqTab ? "w-[14%] px-5" : "w-[20%] px-10"
              } border-0`}
            >
              {fromDate.dateString2}
            </td>
            {isLeaveReqTab ? (
              <>
                <td className=" py-2 w-[14%] px-5 border-0">
                  {toDate.dateString2}
                </td>
                <td className=" py-2 w-[14%] px-5 border-0">{duration}</td>
              </>
            ) : null}
            <td
              className={` py-2 ${
                isLeaveReqTab ? "w-[14%] px-5" : "w-[20%] px-10"
              } border-0`}
            >
              <div className="flex items-center gap-4">
                {isCanceled ? (
                  <>
                    <span className="status status--gray">Cancelled</span>
                  </>
                ) : isForceAction ? (
                  <>
                    <span className={status.leaveStatusClass}>
                      Force {status.leaveStatus} by HR
                    </span>
                  </>
                ) : (
                  <>
                    {status.leaveStatus !== LEVE_PEDNING_STATUS &&
                    Number(managerApprovalStatusId) !== LEVE_REJECT_STATUS ? (
                      <>
                        {/* <img
                src="/images/revoke-icon.svg"
                alt=""
                className="cursor-pointer justify-end"
              /> */}
                        <span className={`${status.leaveStatusClass}`}>
                          {status.leaveStatus}
                        </span>
                        {/* can be changes later when revoke option available */}
                      </>
                    ) : Number(managerApprovalStatusId) === 3 ? (
                      <>
                        <span className="status status--red">
                          Declined by manager
                        </span>
                      </>
                    ) : isEligibleForAction ? (
                      <>
                        <button
                          onClick={() =>
                            handleSetModal(LEAVE_MODAL_ACTIONS.decline, true)
                          }
                        >
                          {closeIconRoundRed}
                        </button>
                        <button
                          onClick={() =>
                            handleSetModal(LEAVE_MODAL_ACTIONS.approve, true)
                          }
                        >
                          {greenTickRound}
                        </button>
                      </>
                    ) : Number(managerApprovalStatusId) === 1 ? (
                      <span className="status status--green">
                        Approved by manager
                      </span>
                    ) : (
                      <span className={`${status.leaveStatusClass}`}>
                        {status.leaveStatus}
                      </span>
                    )}
                  </>
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default LeaveApplicationListData;
