import React, { useEffect, useState, useRef } from "react";
import Modal from "react-responsive-modal";
import { useGetEmployeeDocumetDetailsView } from "../../queries/systemPolicyQueries";
import { DOCUMENT_APPROVAL_STAUS } from "../../Consts/consts";
import useFetchFile from "../../hooks/useFetchFile";
import { SYSTEM_POLICY_FILES_URL } from "../../Consts/filePathConst";
import { FaDownload } from "react-icons/fa";
import { toast } from "react-toastify";

const DocumentDetailsModal = ({ documentId, onClose }) => {
  const { data: document, isLoading } = useGetEmployeeDocumetDetailsView({
    document_id: documentId,
  });
  const getFile = useFetchFile();
  const [fileSrc, setFileSrc] = useState(null);
  const downloadLinkRef = useRef(null);

  const docDetails = document?.data?.[0] || {};
  const {
    src,
    file_name,
    created_by = "N/A",
    document_type = "N/A",
    approved_by = "N/A",
    approved_date,
    approval_status_id,
  } = docDetails;

  const approvalStatusId = Number(approval_status_id);
  const approvedStatusLabel =
    {
      [DOCUMENT_APPROVAL_STAUS.approved]: "Approved",
      [DOCUMENT_APPROVAL_STAUS.declined]: "Declined",
      [DOCUMENT_APPROVAL_STAUS.inProcess]: "In-Process",
      [DOCUMENT_APPROVAL_STAUS.readyForApproval]: "Ready for approval",
    }[approvalStatusId] || "Pending";

  useEffect(() => {
    if (src) {
      const fetchSystemPolicyFile = async () => {
        try {
          const getFileSrc = await getFile(`${SYSTEM_POLICY_FILES_URL}/${src}`);
          setFileSrc(getFileSrc);
        } catch (error) {
          console.error("Error fetching file:", error);
        }
      };
      fetchSystemPolicyFile();
    }
  }, [src]);

  const handleDownload = () => {
    if (!fileSrc) {
      toast.error("No file source available.");
      return;
    }
    if (downloadLinkRef.current) {
      downloadLinkRef.current.click();
      toast.success("Document downloaded successfully!");
    } else {
      toast.error("Download failed.");
    }
  };

  if (isLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg w-96 shadow-lg">Loading...</div>
      </div>
    );
  }

  return (
    <Modal open={documentId !== null} onClose={onClose} center>
      <div className="flex flex-col w-[400px] gap-3">
        <div className="flex justify-between items-center">
          <h1 className="font-medium text-xl text-gray-800">
            Document Details
          </h1>
          <button onClick={onClose}></button>
        </div>
        <table className="w-full border-collapse border border-gray-300">
          <tbody>
            <tr>
              <td className="border border-gray-300 p-2 font-semibold">
                Employee Name
              </td>
              <td className="border border-gray-300 p-2">{created_by}</td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2 font-semibold">
                Document Name
              </td>
              <td className="border border-gray-300 p-2">{document_type}</td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2 font-semibold">
                Updated By
              </td>
              <td className="border border-gray-300 p-2">{approved_by}</td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2 font-semibold">
              Updated Date
              </td>
              <td className="border border-gray-300 p-2">
                {approved_date
                  ? new Date(approved_date).toLocaleDateString()
                  : "N/A"}
              </td>
            </tr>
            <tr>
              <td className="border border-gray-300 p-2 font-semibold">
                Approval Status
              </td>
              <td className="border border-gray-300 p-2">
                <span
                  className={`status ${
                    approvedStatusLabel === "Approved"
                      ? "status--green"
                      : approvedStatusLabel === "Declined"
                      ? "status--red"
                      : approvedStatusLabel === "In-Process"
                      ? "status--yellow"
                      : approvedStatusLabel === "Ready for approval"
                      ? "status--yellow"
                      : "status--yellow"
                  }`}
                >
                  {approvedStatusLabel}
                </span>
              </td>
            </tr>

            {file_name &&
            approvalStatusId === DOCUMENT_APPROVAL_STAUS.approved ? (
              <tr>
                <td className="border border-gray-300 p-2 font-semibold  cursor-pointer">
                  File
                </td>
                <td className="border border-gray-300 p-2">
                  <div className="text-left flex items-center gap-3">
                    {file_name}
                    <FaDownload
                      className="cursor-pointer"
                      onClick={handleDownload}
                    />
                  </div>
                </td>
              </tr>
            ) : (
              <tr>
                <td className="border border-gray-300 p-2 font-semibold">
                  File
                </td>
                <td className="border border-gray-300 p-2 text-gray-500">
                  No document uploaded
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <a
          ref={downloadLinkRef}
          href={fileSrc || "#"}
          download={file_name || "downloaded_document.pdf"}
          style={{ display: "none" }}
        >
          Download
        </a>

        <div className="flex items-center justify-end gap-3 mt-4">
          <button onClick={onClose} className="btn btn--border">
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DocumentDetailsModal;
