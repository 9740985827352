const queryKeys = {
  leaveTypeList: "leaveTypeList",
  leaveHrApprovalList: "leaveHrApprovalList",
  hrAdditionalLeaveApproval: "hrAdditionalLeaveApproval",
  hrLeaveApproval: "hrLeaveApproval",
  leaveHrAdditionalApprovalList: "leaveHrAdditionalApprovalList",
  leavePolicyList: "leavePolicyList",
  leaveManagerApprovalList: "leaveManagerApprovalList",
  additionalLeaveManagerApprovalList: "additionalLeaveManagerApprovalList",
  leaveDetails: "leaveDetails",
  additionalLeaveDetails: "additionalLeaveDetails",
  managerLeaveApproval: "managerLeaveApproval",
  managerAdditionalLeaveApproval: "managerAdditionalLeaveApproval",
  hrLeaveDecline: "hrLeaveDecline",
  hrAdditionalLeaveReject: "hrAdditionalLeaveReject",
  managerLeaveDecline: "managerLeaveDecline",
  managerAdditionalLeaveReject: "managerAdditionalLeaveReject",
  leaveBalances: "leaveBalances",
  leaveRequestList: "leaveRequestList",
  listConditionalLeaveType: "listConditionalLeaveType",
  additionalLeaveListForEmployee: "additionalLeaveListForEmployee",
  applyLeave: "applyLeave",
  applyAdditionalLeaves: "applyAdditionalLeaves",
  cancelLeaveRequest:"cancelLeaveRequest",
  assignShift:"assignShift",
  addNewShift:"addNewShift",
  employeeShiftList : "employee-shift-list",
  shiftList:"get-shift-list",
  getSystemPolicyList:"getSystemPolicyList",
  getSystemPolicyListForSelectBox:"getSystemPolicyListForSelectBox",
  getSystemPolicyAttachments:"getSystemPolicyAttachments",
  createSystemPolicyList:"createSystemPolicyList",
  updateSystemPolicyList:"updateSystemPolicyList",
  systemPolicyApproval:"systemPolicyApproval",
  updateSystemPolicyStatus:"updateSystemPolicyStatus",
  employeeLeaveBalanceList:"employeeLeaveBalanceList",
  listLeavePolicyMapping:"listLeavePolicyMapping",
  manageNewEmployee:"manageNewEmployee",
  employeeList : "employee-list",
  countryList : "get-country-list",
  stateList : "get-state-list",
  cityList : "get-city-list",
  employeeHistory :"employee-history",
  getActionTypesFoeEmployeeHistory :"get-action-types-for-employee-history",
  locationList : "get-location-list",
  departmentList : "get-department-list",
  desginationList : "get-designation-list",
  lineManagerList : "get-line-manager-list",
  hrList : "get-hr-list",
  singleEmployeeDetails:"singleEmployeeDetails",
  employeeTypeList:"employeeTypeList",
  employeeJobList:"employeeJobList",
  employeeSerialKey:"employeeSerialKey",
  adjustLeaveBalance:"adjustLeaveBalance",
  widgetList:"listOfWidgets",
  updateWidgetViewStatus : "updateWidgetViewStatus",
  getEmployeeLeaveBalaceById:"getEmployeeLeaveBalaceById",
  downloadMyShiftData:"downloadMyShiftData",
  downloadEmployeesShift:"downloadEmployeesShift",
  getYearListData:"getYearListData",
  getDefaultYearId:"getDefaultYearId",
  policyRenewalLogData:"policyRenewalLogData",
  holidayDataList:"holidayDataList",
  holidayDetails:"holidayDetails",
  editHoliday:"editHoliday",
  addHoliday:"addHoliday",
  leaveModeListData:"leaveModeListData",
  updateLeaveType:"updateLeaveType",
  leaveModeDetails:"leaveModeDetails",
  renewEmployeePolicy:"renewEmployeePolicy",
  updateLeaveTypeStatus:"updateLeaveTypeStatus",
  getRequestDocumentTypeList:"getRequestDocumentTypeList",
  getEmployeeRequestDocumentTypeListData: "getEmployeeRequestDocumentTypeListData",
  requestEmployeeDocument:"requestEmployeeDocument",
  getEmployeeRequestDocumentTypeHRListData:"getEmployeeRequestDocumentTypeHRListData",
  hrRejectDocument:"hrRejectDocument",
  hrAcceptDocument:"hrAcceptDocument",
  uploadEmployeeDocument:"uploadEmployeeDocument",
  adminDocumentApproval:"adminDocumentApproval",
  adminRejectDocument:"adminRejectDocument"
};

export default queryKeys;
