const formatLeaveApplicationStatus = (hrstatus, managerstatus) => {
  const isString = typeof hrstatus === "string";

  const approveLabel = isString ? "approved" : 1;
  const PendingLabel = isString ? "pending" : 2;
  const declinedLabel = isString ? "declined" : 3;
  const canceledLabel = isString ? "canceled" : 4;

  const APPROVED_LABEL = "Approved";
  const PENDING_LABEL = "Pending";
  const DECLINED_LABEL = "Declined";
  const CANCELED_LABEL = "Cancelled";

  const APPROVED_LABEL_CLASS = "status status--green";
  const PENDING_LABEL_CLASS = "status";
  const DECLINED_LABEL_CLASS = "status status--red";
  const CANCELED_LABEL_CLASS = "status status--gray";

  // we can format the status by using status label or status id

  if (hrstatus && managerstatus) {
    const hrStatus = isString ? hrstatus.toLowerCase() : hrstatus;
    const managerStatus = isString
      ? managerstatus.toLowerCase()
      : managerstatus;
    const leaveStatus =
      hrStatus === approveLabel && managerStatus === approveLabel
        ? APPROVED_LABEL
        : hrStatus === declinedLabel || managerStatus === declinedLabel
        ? DECLINED_LABEL
        : PENDING_LABEL;
    const leaveStatusClass =
      hrStatus === approveLabel && managerStatus === approveLabel
        ? APPROVED_LABEL_CLASS
        : hrStatus === declinedLabel || managerStatus === declinedLabel
        ? DECLINED_LABEL_CLASS
        : PENDING_LABEL_CLASS;

    const hrStatusLabel =
      hrStatus === approveLabel
        ? APPROVED_LABEL
        : hrStatus === declinedLabel
        ? DECLINED_LABEL
        : PENDING_LABEL;
    const hrStatusClass =
      hrStatus === approveLabel
        ? APPROVED_LABEL_CLASS
        : hrStatus === declinedLabel
        ? DECLINED_LABEL_CLASS
        : PENDING_LABEL_CLASS;
    const managerStatusLabel =
      managerStatus === approveLabel
        ? APPROVED_LABEL
        : managerStatus === declinedLabel
        ? DECLINED_LABEL
        : PENDING_LABEL;
    const managerStatusClass =
      managerStatus === approveLabel
        ? APPROVED_LABEL_CLASS
        : managerStatus === declinedLabel
        ? DECLINED_LABEL_CLASS
        : PENDING_LABEL_CLASS;

    const status = {
      leaveStatus,
      leaveStatusClass,
      hrStatusClass,
      managerStatusClass,
      managerStatusLabel,
      hrStatusLabel,
    };

    return status;
  } else if (hrstatus) {
    // here we only consider the status label or statud id
    const hrStatus = isString ? hrstatus.toLowerCase() : hrstatus;
    const leaveStatus =
      hrStatus === approveLabel
        ? APPROVED_LABEL
        : hrStatus === declinedLabel
        ? DECLINED_LABEL
        : hrStatus === canceledLabel
        ? CANCELED_LABEL
        : PENDING_LABEL;
    const leaveStatusClass =
      hrStatus === approveLabel
        ? APPROVED_LABEL_CLASS
        : hrStatus === declinedLabel
        ? DECLINED_LABEL_CLASS
        : hrStatus === canceledLabel
        ? CANCELED_LABEL_CLASS
        : PENDING_LABEL_CLASS;

    const status = {
      leaveStatus,
      leaveStatusClass,
    };

    return status;
  } else {
    return "HR and Manager status label required! OR Status label/ id required";
  }
};
export default formatLeaveApplicationStatus;