import React, { useState } from "react";
import DeleteLeaveTypeModal from "./DeleteLeaveTypeModal";
import ToggleSwitch from "../../../ToggleSwitch/ToggleSwitch";
import AddOrEditLeaveTypeModal from "./AddOrEditLeaveTypeModal";

const LeaveTypeListData = ({ requestdata, key,onToggleStatus  }) => {
  const [isEditLeaveTypeModal, setIsEditLeaveTypeModal] = useState(false);
  const [isDeleteLeaveTypeModal, setIsDeleteLeaveTypeModal] = useState(false);
  const { id, leave_name: leaveName, status, paid, number_days_allowed } =
    requestdata;
  const paidStatus = Number(paid) ? "Paid" : "Unpaid";


  const handleStatusToggle = (newStatus) => {
    onToggleStatus(id, newStatus);
  };

  return (
    <div className="pt-5">
      {isEditLeaveTypeModal && (
        <AddOrEditLeaveTypeModal
          openModal={isEditLeaveTypeModal}
          onCloseModal={() => setIsEditLeaveTypeModal(false)}
          leaveTypeData={requestdata}

          key={key}
        />
      )}
      {isDeleteLeaveTypeModal && (
        <DeleteLeaveTypeModal
          openModal={isDeleteLeaveTypeModal}
          onCloseModal={() => setIsDeleteLeaveTypeModal(false)}
          leaveType={requestdata}

          key={key}
        />
      )}

      <div className="border rounded-md p-5 flex justify-between">
        <div className="flex items-center gap-2">
          <span className={`rounded-full ${status ? "bg-green-300" : "bg-red-300"} h-2 p-2`}></span>
          <div className="flex flex-col">
            <p className="font-medium text-black">{leaveName}</p>
            <div className="flex gap-2">
              <p>System | </p>
              <p>{paidStatus} | </p>
              <p>{number_days_allowed || 0} Days</p>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-3">
        <ToggleSwitch
            checked={!!status}
            getToggleState={(newStatus) => handleStatusToggle(newStatus)}
          />
          <i
            className="ico-edit text-base cursor-pointer"
            onClick={() => setIsEditLeaveTypeModal(true)}
          />
          {/* <i className="ico-copy text-base cursor-pointer" />
          <i
            className="ico-delete text-3xl mt-2.5 cursor-pointer"
            onClick={() => setIsDeleteLeaveTypeModal(true)}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default LeaveTypeListData;
