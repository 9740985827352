import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Select, { components } from "react-select";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import Line from "../../Line/Line";
import { useLeaveTypeList } from "../../../queries/leaveQueries";
import SpinnerLoader from "../../PageLoader/SpinnerLoader";
import { infoIcon } from "../../../Icons/Icons";
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
};

const BulkLeaveSettingModal = ({ open, close }) => {
  const axiosPrivate = useAxiosPrivate();
  const [selectedLeaveType, setSelectedLeaveType] = useState(null);
  const [errMsg, setErrMsg] = useState("");

  const handleCloseModal = () => {
    setSelectedLeaveType(null);
    close();
  };

  const { data: leaveListData, isLoading } = useLeaveTypeList({
    status: 1,
    fullDetails: 1,
  });

  const leaveTypes = leaveListData?.data || [];

  const handleSubmit = async () => {
    if (selectedLeaveType) {
      setErrMsg("");
      let isMounted = true;
      const controller = new AbortController();
      await axiosPrivate
        .post(`lms/leave/bulkSetting`, {
          leaveTypeId: Number(selectedLeaveType.value),
          numberOfDaysToAdd: Number(selectedLeaveType.numberOfDaysAllowed),
        })
        .then((response) => {
          const res = response?.data;
          if (res.status) {
            toast.success(
              `${selectedLeaveType.label} is assigned to all employees`
            );
            handleCloseModal();
          } else {
            toast.error("Something went wrong, try again.");
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error("Failed to assign leave type");
        });

      return () => {
        isMounted = false;
        controller.abort();
      };
    } else {
      setErrMsg("Select a leave type");
    }
  };
  const [maxDaysModalOpen, setMaxDaysModalOpen] = useState(false);
  const [restrictionModalOpen, setRestrictionModalOpen] = useState(false);

  return (
    <>
      <>
        <>
          <Modal open={open} onClose={handleCloseModal} center>
            <div className="p-5 min-w-[420px] text-black">
              <div>
                <p className="font-medium text-lg pb-2">
                  Bulk leave setting for all employee
                </p>
                <Line />
              </div>
              {isLoading ? (
                <div className="flex justify-center">
                  <SpinnerLoader />
                </div>
              ) : Array.isArray(leaveTypes) && leaveTypes.length > 0 ? (
                <div className="flex flex-col h-56 justify-between">
                  <span className="text-red-600 font-medium">{errMsg}</span>
                  <div className="flex flex-col gap-3">
                    <div className="flex flex-col">
                      <Select
                        components={{ DropdownIndicator }}
                        options={
                          leaveTypes?.length &&
                          leaveTypes.map((leave) => ({
                            value: leave.id,
                            label: leave.leave_name,
                            numberOfDaysAllowed: leave.number_days_allowed,
                            isLop: leave.is_lop,
                            max_limit_can_be_accumulated:
                              leave?.max_limit_can_be_accumulated,
                          }))
                        }
                        onChange={(e) => {
                          setSelectedLeaveType(e);
                        }}
                      />
                    </div>
                    {!selectedLeaveType?.isLop ? (
                      <div className="flex items-center gap-3">
                        <label>Number of days allowed:</label>
                        <input
                          type="number"
                          className="w-[150px]"
                          value={selectedLeaveType?.numberOfDaysAllowed}
                          onChange={(e) =>
                            setSelectedLeaveType({
                              ...selectedLeaveType,
                              numberOfDaysAllowed: e.target.value,
                            })
                          }
                        />
                        <span
                          className="cursor-pointer"
                          onClick={() => setMaxDaysModalOpen(true)}
                        >
                          {infoIcon}
                        </span>
                      </div>
                    ) : null}

                    <p className="text-gray-600">
                      Selected leave type will assign to all employees
                      <span
                        className="cursor-pointer inline-flex items-center p-4"
                        onClick={() => setRestrictionModalOpen(true)}
                      >
                        {infoIcon}
                      </span>
                    </p>
                  </div>
                  <div className="flex justify-end gap-3">
                    <button
                      type="button"
                      className="btn btn--border"
                      onClick={handleCloseModal}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn"
                      onClick={handleSubmit}
                    >
                      Assign Leave Types
                    </button>
                  </div>
                </div>
              ) : (
                <p className="text-red-800">No leave types availbale</p>
              )}
            </div>
          </Modal>
        </>
        <Modal
          open={maxDaysModalOpen}
          onClose={() => setMaxDaysModalOpen(false)}
          center
        >
          <div className="p-4">
            <p>
              <span>Maximum days can be accumulated: </span>
              <span style={{ fontWeight: "600" }}>
                {selectedLeaveType?.max_limit_can_be_accumulated || 0}
              </span>
            </p>
          </div>
        </Modal>
      </>
      <>
        {" "}
        <Modal
          open={restrictionModalOpen}
          onClose={() => setRestrictionModalOpen(false)}
          center
        >
          <div className="p-4">
            <p>
              If the leave days exceed the maximum limit, <br />
              the maximum days can be accumulated will apply and any excess will
              be restricted.
            </p>
          </div>
        </Modal>
      </>
    </>
  );
};

export default BulkLeaveSettingModal;
