import React, { useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";
import userRoles from "../../config/userRoles";

const PolicyModule = () => {
  const [submenu, setSubmenu] = useState([
    {
      text: "Policy",
      link: "/policy/policy-list",
      icon: "ico-down",
      roles: [userRoles.TENANTADMIN, userRoles.EMPLOYEE, userRoles.HR, userRoles.MANAGER],
      // menu: [
      //   {
      //     text: "Regularization",
      //     link: "#",
      //     roles: [2, 4],
      //   },
      // ],
      status: true,
    },
    {
      text: "Manage Documents",
      link: "/policy/manage-documents",
      icon: "ico-down",
      roles: [userRoles.TENANTADMIN, userRoles.EMPLOYEE, userRoles.HR, userRoles.MANAGER],
    
      status: true,
    },
  ]);
  return <DashboardLayout asideBarMenu={submenu} />;
};

export default PolicyModule;
